import React from 'react';
import { connect } from 'react-redux';
import { StyleSheet, css } from 'aphrodite';
import { withRouter } from 'react-router-dom';
import { fadeIn } from 'react-animations';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { UserIcon, SharedUserIcon } from 'utilities/icons';

import { fetchSiteUserList } from 'actions/Users';
import { fetchActivityCSVList } from 'actions/Activity';
import { showMessage } from 'actions/App';

import Spinner from 'components/Spinner';
import Header from 'components/Header/dashboard';
class DashboardRecentUsers extends React.Component {
    constructor(props) {
        super(props);
        let defaultNumEntry = 5;
        this.state = {
            defaultNumEntry,
            showNumEntry: defaultNumEntry
        };
    }
    componentDidMount() {
        const { authUser, fetchSiteUserList, editWidgets, visible } = this.props;
        authUser &&
            authUser.permissions.includes('view_users') &&
            (editWidgets || visible) &&
            fetchSiteUserList(undefined, true);
    }
    handleUserAction = (data) => {
        this.props.history.push(`/app/users/display/${data.user}`);
    };
    exportActivity = () => {
        const { fetchActivityCSVList, activityFilters, recentUsers, siteUserList, showMessage, width } = this.props;
        let showNumEntry = width > 400 ? 5 : 4;
        let filteredRecentUsers =
            recentUsers && siteUserList
                ? recentUsers.filter((user) => user.type == 'client').map((user) => user.user)
                : null;
        let filters = JSON.parse(JSON.stringify(activityFilters));
        filters.users = filteredRecentUsers.length > 0 ? filteredRecentUsers.slice(0, showNumEntry) : [];
        filters.date.start = moment().subtract(1, 'months').format('YYYY-MM-DD');
        fetchActivityCSVList(1, filters, []);
        showMessage(`comp.activity.export.email`, 'success');
    };
    render() {
        const { intl, theme, width, recentUsers, siteUserList, authUser, editWidgets, handleDateSelection, active } =
            this.props;
        this.styles = styles(theme, width);
        recentUsers &&
            siteUserList &&
            recentUsers.map((user) =>
                siteUserList.map((siteUser) => {
                    if (user.user == siteUser.id) {
                        user.type = siteUser.type;
                    }
                })
            );
        let filteredRecentUsers =
            recentUsers && siteUserList ? recentUsers.filter((user) => user.type == 'client') : null;
        let showNumEntry = width > 400 ? 5 : 4;
        return (
            <>
                <Header
                    handleDateSelection={handleDateSelection}
                    widgetId={4}
                    active={active}
                    Icon={SharedUserIcon}
                    iconSize={38}
                    translatedTitle={`${intl.formatMessage({ id: 'comp.sidebar.mostactive' })} ${intl.formatMessage({
                        id: 'comp.table.header.users'
                    })}`}
                    manage={
                        !editWidgets &&
                        authUser &&
                        authUser.permissions.includes('export_activity') &&
                        authUser.email &&
                        authUser.email !== '' &&
                        filteredRecentUsers && 
                        filteredRecentUsers.length > 0 && [
                            {
                                icon: 'get_app',
                                color: theme.color.buttonFontColor,
                                text: 'comp.activity.export',
                                action: () => this.exportActivity(),
                                id: 'recentUsers-get_app'
                            }
                        ]
                    }
                />
                {filteredRecentUsers ? (
                    <div className={css(this.styles.itemRow)}>
                        {filteredRecentUsers && filteredRecentUsers.length > 0 ? (
                            filteredRecentUsers.slice(0, editWidgets ? 3 : showNumEntry).map(
                                (recentUser) =>
                                    recentUser.user !== ' ' && (
                                        <div
                                            key={'USERLIST' + recentUser.user}
                                            className={editWidgets ? css(this.styles.itemEdit) : css(this.styles.item)}
                                            onClick={() => this.handleUserAction(recentUser)}>
                                            <div className={css(this.styles.icon)}>
                                                <UserIcon
                                                    fill={theme.color.themeColor}
                                                    color={theme.color.buttonFontColor}
                                                    width={editWidgets ? 15 : 25}
                                                    height={editWidgets ? 15 : 25}
                                                />
                                            </div>
                                            <div className={css(this.styles.text)}>
                                                <p
                                                    className={
                                                        editWidgets
                                                            ? css(this.styles.titleEdit)
                                                            : css(this.styles.title)
                                                    }>
                                                    {recentUser.name
                                                        ? recentUser.name == ' ' && recentUser.user !== ' '
                                                            ? recentUser.user
                                                            : recentUser.name
                                                        : intl.formatMessage({ id: 'form.none' })}
                                                </p>
                                                <p
                                                    className={
                                                        editWidgets
                                                            ? css(this.styles.countEdit)
                                                            : css(this.styles.count)
                                                    }>
                                                    {recentUser.count}{' '}
                                                    {recentUser.count == 0
                                                        ? intl.formatMessage({ id: 'form.event' })
                                                        : intl.formatMessage({ id: 'form.events' })}
                                                </p>
                                            </div>
                                        </div>
                                    )
                            )
                        ) : (
                            <div
                                style={{
                                    minHeight: 110,
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    animationName: fadeIn,
                                    animationDuration: '1.2s'
                                }}>
                                <p>{intl.formatMessage({ id: 'comp.table.cell.empty' })}</p>
                            </div>
                        )}
                    </div>
                ) : (
                    <div style={{ minHeight: 145 }}>
                        <Spinner />
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, activity, users }) => {
    const { theme, width } = app;
    const { authUser } = auth;
    const { recentUsers, activityFilters } = activity;
    const { siteUserList } = users;
    return { theme, width, recentUsers, activityFilters, siteUserList, authUser };
};

export default withRouter(
    injectIntl(connect(mapStateToProps, { fetchSiteUserList, fetchActivityCSVList, showMessage })(DashboardRecentUsers))
);

const styles = (theme, width) =>
    StyleSheet.create({
        header: {
            color: theme.color.themeColor,
            marginBottom: 15
        },
        headerEdit: {
            color: theme.color.themeColor,
            marginBottom: 10,
            fontSize: 20
        },
        itemRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            padding: '5px 0 20px 0',
            margin: '0 20px 20px 20px',
            maxHeight: 145,
            flexWrap: 'wrap',
            overflowY: 'auto'
        },
        item: {
            width: '18%',
            margin: '6px 2% 0 2%',
            minHeight: 100,
            minWidth: 83,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderRadius: 5,
            transition: 'all .2s ease-in-out',
            overflow: 'hidden',
            boxShadow: theme.color.boxShadowCorner,
            ':hover': {
                cursor: 'pointer',
                transform: 'translateY(-2px)',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-width:400px)': {
                width: '23%'
            }
        },
        itemEdit: {
            width: '33%',
            margin: '0 1%',
            minHeight: 100,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'flex-start',
            borderRadius: 5,
            transition: 'all .2s ease-in-out',
            overflow: 'hidden',
            boxShadow: theme.color.boxShadowCorner,
            ':hover': {
                cursor: 'pointer',
                transform: 'translateY(-2px)',
                boxShadow: theme.color.boxShadowCornerHover
            },
            '@media(max-width:400px)': {
                width: '23%'
            }
        },
        icon: {
            width: '100%',
            height: 42,
            backgroundColor: theme.color.themeColor,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        text: {
            display: 'flex',
            height: 60,
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center'
        },
        title: {
            marginTop: 5,
            marginBottom: 0,
            textAlign: 'center',
            fontSize: 11,
            width: '80%',
            maxWidth: '100%',
            textTransform: 'capitalize',
            maxHeight: 30,
            overflow: 'hidden',
            wordBreak: 'break-word',
            '@media(max-width:1350px)': {
                fontSize: 9
            }
        },
        titleEdit: {
            marginTop: 5,
            marginBottom: 0,
            textAlign: 'center',
            fontSize: 9,
            width: '80%',
            maxWidth: '100%',
            textTransform: 'capitalize',
            maxHeight: 30,
            overflow: 'hidden',
            wordBreak: 'break-word',
            '@media(max-width:1350px)': {
                fontSize: 6
            }
        },
        count: {
            marginBottom: 5,
            fontWeight: 600,
            textAlign: 'center',
            fontSize: 11,
            width: 60,
            textTransform: 'capitalize',
            '@media(max-width:1350px)': {
                fontSize: 9
            }
        },
        countEdit: {
            marginBottom: 5,
            fontWeight: 600,
            textAlign: 'center',
            fontSize: 9,
            width: 60,
            textTransform: 'capitalize',
            '@media(max-width:1350px)': {
                fontSize: 6
            }
        },
        export: {
            color: theme.color.themeColor,
            display: 'inline-block',
            float: 'right',
            opacity: 1,
            marginRight: 5,
            marginTop: width > 1400 ? 0 : 2,
            fontSize: width > 1400 ? 22 : 18,
            transition: 'all .2s ease-in-out',
            ':hover': {
                cursor: 'pointer',
                opacity: 0.8
            }
        },
        titleBox: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        }
    });
