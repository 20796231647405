import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { history } from 'store/history';
import * as API from 'api';
import * as UTILS from 'utilities';
import * as ICONS from 'utilities/icons';
import * as CONSTANTS from 'constants';
import * as ACTIONS_APP from 'actions/App';
import * as ACTIONS_AUTH from 'actions/Auth';
import * as ACTIONS_SETTINGS from 'actions/Settings';
import * as ACTIONS_USERS from 'actions/Users';
import * as ACTIONS_UNITS from 'actions/Units';
import * as ENDPOINTS from 'constants/endpoints';
import Moment from 'moment-timezone';
import { extendMoment } from 'moment-range';
import { fetchUserDetails } from '../actions/Users';

const moment = extendMoment(Moment);

const getUsersUnitHeaders = (state) => state.units.usersUnitsHeader;
const getUserHeaders = (state) => state.users.usersHeader;
const getUsersFilters = (state) => state.users.usersFilters;
const getActivityState = (state) => state.activity;
const getAuthUser = (state) => state.auth.authUser;
const getSite = (state) => state.auth.site;
const getStatusFilter = (state) => state.users.userFilterStatus;
const getServiceFilter = (state) => state.users.userFilterService;
const getScheduleFilter = (state) => state.users.userFilterSchedule;
const getUserTimezone = (state) => state.app.userTimezone;
const getOnsiteUsers = (state) => state.users.onsiteUsers;
const getTransferHeaders = (state) => state.users.transferHeaders;

function* requestSiteUserList({ payload }) {
    try {
        if (payload.activeUsers|| payload.changingSchedule) {
            // don't set to null if activeUsers is set on schedules page
        } else {
            yield put(ACTIONS_USERS.setSiteUserList(null));
        }
        yield put(ACTIONS_USERS.setCompanyUserListStats(null));
        let users = yield call(API.POST, payload.url, payload.data);
        if (!users || !users.data || !users.data.users) {
            yield put(ACTIONS_USERS.setSiteUserList([]));
            if (payload.fetchStats) {
                let usersFilters = yield select(getUsersFilters);
                let allFilters = usersFilters.find((filter) => filter.title === 'All');
                let stats = {
                    total: 0,
                    subTotals:
                        allFilters &&
                        allFilters.value.map((item) => {
                            return {
                                title: item,
                                total: 0
                            };
                        })
                };
                let statusFilters = ['active', 'update_required'];
                let statusStats = {
                    total: 0,
                    subTotals: statusFilters.map((item) => {
                        return {
                            title: item,
                            total: 0
                        };
                    })
                };
                let serviceStats = {
                    total: 0,
                    subTotals: [
                        {
                            title: 'users',
                            total: 0
                        },
                        {
                            title: 'service_users',
                            total: 0
                        }
                    ]
                };
                yield put(ACTIONS_USERS.setSiteUserListStats(stats));
                yield put(ACTIONS_USERS.setSiteUserListStatusStats(statusStats));
                yield put(ACTIONS_USERS.setSiteUserListServiceStats(serviceStats));
            }
        } else {
            if (payload.activeUsers || payload.changingSchedule) {
                yield put(ACTIONS_USERS.setUserEditedSchedule(null));
            }
            let activityState = yield select(getActivityState);
            let authUser = yield select(getAuthUser);
            users.data.users.map((user) => {
                user.name = `${user.firstName} ${user.lastName}`;
                user.Icon = ICONS.UserIcon;
                user.units = user.units ? user.units : [];
                user.state = user.state ? user.state : 'update_required';
                user.active =
                    activityState.activityFilters.users && activityState.activityFilters.users.includes(user.uuid);
                user.role = user.roles.find(
                    (role) => role.siteUUID == JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
                )
                    ? user.roles.find(
                          (role) => role.siteUUID == JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
                      )
                    : user.roles[0];
                user.schedule =
                    authUser &&
                    authUser.featureflagAssignments &&
                    authUser.featureflagAssignments.includes('threeScheduleEnabled') &&
                    authUser.featureflagAssignments.includes('newScheduleEnabled')
                        ? user.role.userSiteHoursSuiteName && user.role.userSiteHoursSuiteName !== ''
                            ? user.role.userSiteHoursSuiteName
                            : ''
                        : authUser &&
                          authUser.featureflagAssignments &&
                          !authUser.featureflagAssignments.includes('threeScheduleEnabled') &&
                          !authUser.featureflagAssignments.includes('newScheduleEnabled')
                        ? user.role.userHoursSuiteName && user.role.userHoursSuiteName !== ''
                            ? user.role.userHoursSuiteName
                            : ''
                        : '';
                user.roleName = user.role ? user.role.name : '';
            });

            ///////////////////////
            ///// STATS STUFF /////
            ///////////////////////

            // only do this if we need to (currently only on dashboard page and users page)
            if (payload.fetchStats) {
                let usersFilters = yield select(getUsersFilters);
                let allFilters = usersFilters.find((filter) => filter.title === 'All');
                let stats = {
                    total: users.data.users.length,
                    subTotals:
                        allFilters &&
                        allFilters.value.map((item) => {
                            return {
                                title: item,
                                // total: users.data.users.filter((user) => user.roleName === item).length
                                total: (users.data.users.filter((user) => user.role && user.roleName === item && user.state !== 'inactive').length)
                            };
                        })
                };
                let statusFilters = ['active', 'update_required'];
                let statusStats = {
                    total: users.data.users.length,
                    subTotals: statusFilters.map((item) => {
                        return {
                            title: item,
                            total: users.data.users.filter((user) => user.state === item).length
                        };
                    })
                };
                let serviceStats = {
                    total: users.data.users.length,
                    subTotals: [
                        {
                            title: 'users',
                            total: (users.data.users.filter((user) => user.role && !user.role.isServiceUser && user.state !== 'inactive').length)
                        },
                        {
                            title: 'service_users',
                            total: users.data.users.filter((user) => user.role && user.role.isServiceUser).length
                        }
                    ]
                };
                let other = stats.total;
                stats.subTotals &&
                    stats.subTotals.forEach((item) => {
                        other = other - item.total;
                    });
                stats.total = stats.total - other - ((user) => (user.state !== 'inactive')).length
                yield put(ACTIONS_USERS.setSiteUserListStats(stats));
                yield put(ACTIONS_USERS.setSiteUserListStatusStats(statusStats));
                yield put(ACTIONS_USERS.setSiteUserListServiceStats(serviceStats));
            }

            ///////////////////////
            ///// USERS STUFF /////
            ///////////////////////

            let usersFilters = yield select(getUsersFilters);
            let filters = usersFilters.find((filter) => filter.active);
            users.data.users = filters
                ? UTILS.filterArray(users.data.users, 'roleName', filters.value)
                : users.data.users;
            let userHeaders = yield select(getUserHeaders);
            let userFilterStatus = yield select(getStatusFilter);
            let userFilterService = yield select(getServiceFilter);
            let userFilterSchedule = yield select(getScheduleFilter);
            let activeHeader = userHeaders.find((item) => item.active);
            users.data.users = UTILS.sortList(!activeHeader.order, users.data.users, activeHeader.sortTitle);
            users.data.users =
                userFilterStatus && userFilterStatus == 'active'
                    ? users.data.users.filter((user) => user.state == 'active')
                    : userFilterStatus == 'updateRequired'
                    ? users.data.users.filter((user) => user.state == 'update_required')
                    : users.data.users;
            users.data.users =
                userFilterSchedule && userFilterSchedule == 'default'
                    ? users.data.users.filter((user) => user.role && user.role.schedulePosition == 1)
                    : userFilterSchedule == 'office'
                    ? users.data.users.filter((user) => user.role && user.role.schedulePosition == 2)
                    : userFilterSchedule == 'custom'
                    ? users.data.users.filter((user) => user.role && user.role.schedulePosition == 3)
                    : userFilterSchedule == '24hour'
                    ? users.data.users.filter((user) => user.role && user.role.schedulePosition == 0)
                    : users.data.users;
            users.data.users = payload.activeUsers
                ? users.data.users.filter((user) => user.state == 'active')
                : users.data.users;
            users.data.users =
                userFilterService && userFilterService == 'users'
                    ? users.data.users.filter((user) => user.role && !user.role.isServiceUser)
                    : userFilterService == 'serviceUsers'
                    ? users.data.users.filter((user) => user.role && user.role.isServiceUser)
                    : users.data.users;
            if(payload.dashboardStatus){
                let nameUsers = users.data.users.filter((user) => ((user.state !== 'inactive') && (user.firstName !== '') && (user.lastName !== '')))
                let noNameUsers = users.data.users.filter((user) => ((user.state !== 'inactive') && user.firstName === '') && (user.lastName === ''))
                users.data.users = [...nameUsers, ...noNameUsers];
            }
            yield put(ACTIONS_USERS.setSiteUserList(users.data.users));
            yield put(ACTIONS_USERS.setUserFilters(usersFilters));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setSiteUserList([]));
        yield put(ACTIONS_USERS.setCompanyUserListStats(null));
        console.warn(error);
    }
}

function* requestRemovedUsers({ payload }) {
    try {
        yield put(ACTIONS_USERS.setRemovedUsers(null));
        let authUser = yield select(getAuthUser);
        let site = yield select(getSite);
        let userTimezone = yield select(getUserTimezone);
        let updatedPayload = JSON.parse(JSON.stringify(payload.data));
        let rangeStartDate =
            authUser && authUser.useSiteTime && site
                ? moment(updatedPayload.begin, 'YYYY-MM-DDTHH:mm:ss')
                      .tz(site.timeZone)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                : moment(updatedPayload.begin, 'YYYY-MM-DDTHH:mm:ss')
                      .tz(userTimezone)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        let rangeEndDate =
            authUser && authUser.useSiteTime && site
                ? moment(updatedPayload.end, 'YYYY-MM-DDTHH:mm:ss')
                      .tz(site.timeZone)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss') + 'Z'
                : moment(updatedPayload.end, 'YYYY-MM-DDTHH:mm:ss')
                      .tz(userTimezone)
                      .utc()
                      .format('YYYY-MM-DDTHH:mm:ss') + 'Z';
        updatedPayload.begin = rangeStartDate ? rangeStartDate : updatedPayload.begin;
        updatedPayload.end = rangeEndDate ? rangeEndDate : updatedPayload.end;
        let users = payload.data.range
            ? yield call(API.POST, payload.url, updatedPayload)
            : yield call(API.POST, payload.url, payload.data);
        if (!users) {
            return;
        } else {
            yield put(ACTIONS_USERS.setRemovedUsers(users.data.manualOffSiteData));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setRemovedUsers([]));
        console.warn(error);
    }
}

function* requestUserDetails({ payload }) {
    try {
        yield put(ACTIONS_USERS.setUserDetails(null));
        let user = yield call(API.POST, payload.url, payload.data);
        if (!user) {
          
            yield put(ACTIONS_USERS.setUserDetails(null));
            yield history.goBack();
        } else {
            let units = yield call(API.POST, ENDPOINTS.ENDPOINT_UNIT_LIST, {});
           
            !units.data.units ? (units.data.units = []) : units.data.units;
            let authUser = yield select(getAuthUser);
           
            if (!user.data.permissions) {
                user.data.permissions = [];
            }
            if (!user.data.roles) {
                user.data.roles = [];
            }
            user.data.units = !units
                ? []
                : units.data.units.filter((unit) => {
                      return unit.User.uuid === user.data.uuid;
                  });
            user.data.name = `${user.data.firstName} ${user.data.lastName}`;
            user.data.add_sites = [];
            user.data.remove_sites = [];
            user.data.sites ? user.data.sites.map((site) => user.data.add_sites.push(site.id)) : (user.data.sites = []);
            user.data.role =
                authUser && authUser.uuid == user.data.uuid && authUser.role.tier === 1
                    ? user.data.roles.find((role) => role.siteUUID === '0')
                    : user.data.roles.find(
                          (role) => role.siteUUID === JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
                      );      
            if (!user.data.role) {
                yield history.push('/app/users/all/');
                yield put(ACTIONS_APP.showMessage(`errorCodes.description.48`, 'warning'));
            }
            yield put(ACTIONS_USERS.setUserDetails(user.data));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setUserDetails(null));
        yield history.goBack();
        console.warn(error);
    }
}

function* requestUserPhoneDetails({ payload }) {
    try {
        yield put(ACTIONS_USERS.setUserPhoneDetails(null));
        let phone = yield call(API.POST, payload.url, payload.data);
        if (!phone || !phone.data) {
            yield put(ACTIONS_APP.setLoader(false));
            yield put(ACTIONS_USERS.setUserPhoneDetails('blank'));
            return;
        } else {
            yield put(ACTIONS_APP.setLoader(false));
            yield put(ACTIONS_USERS.setUserPhoneDetails(phone.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserFobs({ payload }) {
    try {
        yield put(ACTIONS_USERS.setUserFobs(null));
        let fobs = yield call(API.POST, payload.url, payload.data);
        if (!fobs) {
            yield put(ACTIONS_USERS.setUserFobs([]));
        } else {
            yield put(ACTIONS_USERS.setUserFobs(fobs.data.fobs));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setUserFobs([]));
        console.warn(error);
    }
}

function* requestUserShares({ payload }) {
    try {
        yield put(ACTIONS_USERS.setUserShares(null));
        let shares = yield call(API.POST, payload.url, payload.data);
        if (!shares) {
            yield put(ACTIONS_USERS.setUserShares([]));
        } else {
            yield put(ACTIONS_USERS.setUserShares(shares.data));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setUserShares([]));
        console.warn(error);
    }
}

function* requestUserPin({ payload }) {
    try {
        yield put(ACTIONS_USERS.setUserPin(null));
        let pin = yield call(API.POST, payload.url, payload.data);
        if (!pin) {
            return;
        } else {
            yield put(ACTIONS_USERS.setUserPin(pin.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestLoginAttempts({ payload }) {
    try {
        yield put(ACTIONS_USERS.setUserLoginAttempts(null));
        let logins = yield call(API.POST, payload.url, payload.data);
        if (!logins) {
            return;
        } else {
            yield put(ACTIONS_USERS.setUserLoginAttempts(logins.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserCreate({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        if (payload.data.email === '') {
            if (key === 'phone' && required[key].length < 4) {
                yield put(ACTIONS_APP.showMessage(`notifications.error.short.phone`, 'warning'));
                return;
            }
        }
        if (payload.data.email && payload.data.email !== '' && !UTILS.validateEmail(payload.data.email)) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.email`, 'warning'));
            return;
        }
        if (payload.data.type === 'client') {
            if (payload.data.accessCode.length < 0 && payload.data.accessCode.length < 4) {
                yield put(ACTIONS_APP.showMessage(`notifications.error.fail.accessCode.short`, 'warning'));
                return;
            }
            if (payload.data.accessCode.length < 0 && payload.data.accessCode.length > 12) {
                yield put(ACTIONS_APP.showMessage(`notifications.error.fail.accessCode.long`, 'warning'));
                return;
            }
            yield put(ACTIONS_UNITS.assignUnit(payload.data));
        } else {
            const update = yield call(API.POST, payload.url, payload.data);
            yield put(ACTIONS_APP.setSaveLoader(false));
            if (!update) {
                return;
            } else if (update.message === 'Work done, but could not send text and/or email to the user.') {
                yield put(ACTIONS_APP.showMessage(`notifications.success.create.workDone`, 'success'));
                if (payload.userSiteHoursSuiteUUID && update.data && update.data.uuid) {
                    yield put(
                        ACTIONS_SETTINGS.updateUserSchedule({
                            userUUID: update.data.uuid,
                            userSiteHoursSuiteUUID: payload.userSiteHoursSuiteUUID
                        })
                    );
                } else {
                    yield history.push(`/app/users/all`);
                }
            } else {
                yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
                if (payload.userSiteHoursSuiteUUID && update.data && update.data.uuid) {
                    yield put(
                        ACTIONS_SETTINGS.updateUserSchedule({
                            userUUID: update.data.uuid,
                            userSiteHoursSuiteUUID: payload.userSiteHoursSuiteUUID
                        })
                    );
                } else {
                    yield update && update.data && update.data.id
                        ? history.push(`/app/users/display/${update.data.uuid}`)
                        : history.push(`/app/users/all`);
                }
            }
        }
    } catch (error) {
        console.warn(error);
        yield put(ACTIONS_APP.setSaveLoader(false));
    }
}

function* requestServiceUserCreate({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        if (payload.data.email === '') {
            if (key === 'phone' && required[key].length < 4) {
                yield put(ACTIONS_APP.showMessage(`notifications.error.short.phone`, 'warning'));
                return;
            }
        }
        if (payload.data.email && payload.data.email !== '' && !UTILS.validateEmail(payload.data.email)) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.email`, 'warning'));
            return;
        }
        if (payload.data.accessCode.length < 0 && payload.data.accessCode.length < 4) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.accessCode.short`, 'warning'));
            return;
        }
        if (payload.data.accessCode.length < 0 && payload.data.accessCode.length > 12) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.accessCode.long`, 'warning'));
            return;
        }
        if (payload.data.zoneUUIDs.length < 1) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.fail.noZone`, 'warning'));
            return;
        }
        const create = yield call(API.POST, payload.url, payload.data);
        yield put(ACTIONS_APP.setSaveLoader(false));
        if (!create) {
            return;
        } else if (create.message === 'Work done, but could not send text and/or email to the user.') {
            yield put(ACTIONS_APP.showMessage(`notifications.success.create.workDone`, 'success'));
            yield history.push(`/app/users/all`);
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.addServiceUser`, 'success'));
            yield history.push(`/app/users/display/${create.data.userUUID}`);
        }
    } catch (error) {
        yield put(ACTIONS_APP.setSaveLoader(false));
        console.warn(error);
    }
}

function* requestUserEdit({ payload }) {
    try {
        let required = payload.required;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_USERS.setUserDetails(null));
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            let authUser = JSON.parse(sessionStorage.getItem('user_data'));
            let siteUUID = JSON.parse(localStorage.getItem('nse_login_data')).siteUUID || authUser.defaultSiteUUID;
            if (authUser.uuid == payload.data.userUUID) {                           
                yield put(ACTIONS_APP.setSwitcherLoader({loading: true, text: ''}));
                let simpleView;
                if (update.data.simplifiedView) {
                    simpleView = 'ON';
                } else {
                    simpleView = 'OFF';
                }
                yield put(ACTIONS_AUTH.switchSite(siteUUID, false, false, false, false, true, simpleView));
                if(payload.data.simpleBar)
                    update.data.roles && update.data.roles.filter((role) => role.tier === 1)[0]
                    ? yield put(ACTIONS_USERS.fetchSupportUserDetails(payload.data.userUUID))
                    : yield put(ACTIONS_USERS.fetchUserDetails(payload.data.userUUID));
                
            } else {
                update.data.roles && update.data.roles.filter((role) => role.tier === 1)[0]
                    ? yield put(ACTIONS_USERS.fetchSupportUserDetails(payload.data.userUUID))
                    : yield put(ACTIONS_USERS.fetchUserDetails(payload.data.userUUID));
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserDelete({ payload }) {
    try {
        const deleteUser = yield call(API.POST, payload.url, payload.data);
        if (!deleteUser) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.failed`, 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.delete`, 'success'));
            payload.support ? yield history.push(`/app/support`) : yield history.push(`/app/users`);
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserEditRole({ payload }) {
    try {
        let authUser = yield select(getAuthUser);
        let checker = false;
        if (payload.role.tier === 0) {
            checker = true;
        } else if (payload.role.tier === 1) {
            checker = false;
        } else if (payload.role.tier >= authUser.role.tier) {
            checker = true;
        }
        if (!checker) {
            yield put(ACTIONS_APP.showMessage(`notifications.error.role.deny`, 'warning'));
            yield put(ACTIONS_APP.setSaveLoader(false));
            return;
        }
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserEditPermissions({ payload }) {
    try {
        payload.data.permissions = [...new Set(payload.data.permissions)];
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserEditUserExpiration({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield put(ACTIONS_USERS.fetchUserDetails(payload.data.userId.toString()));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestConfirmation({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'))
            let siteUUID = JSON.parse(localStorage.getItem('nse_login_data')).siteUUID || authUser.defaultSiteUUID;
            if (payload.refresh) {
                let authUser = JSON.parse(sessionStorage.getItem('user_data'));
                let site = authUser.sites.find((site) => site.uuid === siteUUID);
                yield put(ACTIONS_APP.fetchStartup(authUser, site));
            }
            if (Array.isArray(payload.data.remove_sites_uuid)) {
                // Check if siteUUID exists in remove_sites_uuid array
                if (payload.data.remove_sites_uuid.includes(siteUUID)) {
                    yield history.push('/app/users/all/');
                } else {
                    yield put(ACTIONS_USERS.fetchUserDetails(payload.data.userUUID));
                }
            } else if(payload.data.remove_sites_uuid != siteUUID )
                yield put(ACTIONS_USERS.fetchUserDetails(payload.data.userUUID));
            else{
                yield history.push('/app/users/all/');
            }
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestConfirmationAll({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('comp.users.activateAll', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSaveUserDailyHours({ payload }) {
    try {
        let required = payload.data;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_USERS.setUserDetails(null));
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            yield put(ACTIONS_USERS.fetchUserDetails(payload.data.userId));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestDeleteUserDailyHours({ payload }) {
    try {
        let required = payload.data;
        for (var key in required) {
            if (required[key] === undefined || required[key] === '') {
                yield put(ACTIONS_APP.showMessage(`notifications.error.blank.${key}`, 'warning'));
                return;
            }
        }
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_USERS.setUserDetails(null));
            yield put(ACTIONS_APP.showMessage(`notifications.success.delete`, 'success'));
            yield put(ACTIONS_AUTH.setSite(payload.site));
            yield put(ACTIONS_APP.setSwitcherLoader({loading: true, text: ''}));
            yield put(ACTIONS_AUTH.switchSite(payload.site.uuid));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestUserAcknowledgeTraining({ payload }) {
    try {
        const acknowledge = yield call(API.POST, payload.url, payload.data);
        if (!acknowledge) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.acceptedTraining`, 'success'));
            yield put(ACTIONS_APP.setDashboardWidgets(null));
            let authUser = JSON.parse(sessionStorage.getItem('user_data'));
            let siteUUID = JSON.parse(localStorage.getItem('nse_login_data')).siteUUID || authUser.defaultSiteUUID;
            yield put(ACTIONS_APP.setSwitcherLoader({loading: true, text: ''}));
            yield put(ACTIONS_AUTH.switchSite(siteUUID));
            //window.location.reload();
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchUserUnits({ payload }) {
    try {
        yield put(ACTIONS_USERS.setUserUnits(null));
        yield put(ACTIONS_USERS.setUserKeypadStatus(false));
        let units = yield call(API.POST, payload.url, payload.data);
        if (!units) {
            return;
        } else {
            let keypadStatus = units.data.units.filter((unit) => unit.accessType === 'site');
            units.data.units = units.data.units.filter((unit) => unit.accessType !== 'site');
            let usersUnitHeaders = yield select(getUsersUnitHeaders);
            usersUnitHeaders[2].hide =
                units.data.units.filter((unit) => unit.detailsPrice && unit.detailsPrice !== 0).length > 0
                    ? false
                    : true;
            usersUnitHeaders[3].hide =
                units.data.units.filter((unit) => unit.detailsDepth && unit.detailsDepth !== 0).length > 0
                    ? false
                    : true;
            usersUnitHeaders[4].hide =
                units.data.units.filter((unit) => unit.detailsWidth && unit.detailsWidth !== 0).length > 0
                    ? false
                    : true;
            yield put(ACTIONS_USERS.setUserUnits(units.data.units));
            yield put(ACTIONS_USERS.setUserKeypadStatus(keypadStatus && keypadStatus.length > 0 ? true : false));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSendUserAccesscode({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.sentAccessCodes', 'success'));
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEditUserSettings({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            return;
        } else {
            yield put(ACTIONS_APP.showMessage(`notifications.success.edit`, 'success'));
            let authUser = JSON.parse(sessionStorage.getItem('user_data'));
            authUser.language = update.data.language;
            authUser.temperatureScale = update.data.temperatureScale;
            authUser.useSiteTime = update.data.useSiteTime;
            authUser.preferredDateFormat = update.data.preferredDateFormat;
            authUser.name = `${authUser.firstName} ${authUser.lastName}`;
            sessionStorage.setItem('user_data', JSON.stringify(authUser));
            if(authUser.permissions.includes('view_users')) {
                yield put(ACTIONS_USERS.fetchUserDetails(payload.user));
            }
            yield put(ACTIONS_AUTH.userSignInSuccess(authUser));
            yield put(ACTIONS_USERS.setUserDetails(update.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEdit2FactorSelf({ payload }) {
    try {
        const update = yield call(API.POST, payload.url, payload.data);
        if (!update) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            let authUser = JSON.parse(sessionStorage.getItem('user_data'));
            authUser.methodFor2FactorAuth = update.data.methodFor2FactorAuth;
            authUser.use2FactorAuth = update.data.use2FactorAuth;
            authUser.name = `${authUser.firstName} ${authUser.lastName}`;
            sessionStorage.setItem('user_data', JSON.stringify(authUser));
            if (payload.push) {
                yield history.goBack();
            }
            yield put(ACTIONS_AUTH.userSignInSuccess(authUser));
            yield put(ACTIONS_USERS.setUserDetails(update.data));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestEdit2Factor({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_USERS.setUserDetails(null));
            yield put(ACTIONS_APP.showMessage('notifications.success.edit', 'success'));
            yield put(ACTIONS_USERS.fetchUserDetails(confirm.data.uuid));
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchSupportUserList({ payload }) {
    try {
        let authUser = yield select(getAuthUser);
        yield put(ACTIONS_USERS.setSupportUsers(null));
        let users = yield call(API.POST, payload.url, payload.data);
        if (!users || !users.data || !users.data.users) {
            yield put(ACTIONS_USERS.setSupportUsers([]));
        } else {
            users.data.users.map((user) => {
                user.name = `${user.firstName} ${user.lastName}`;
                user.Icon = ICONS.UserIcon;
                user.role = authUser.role;
                user.roleName = authUser.roleName;
            });
            users.data.users = UTILS.sortList(false, users.data.users, 'name');
            yield put(ACTIONS_USERS.setSupportUsers(users.data.users));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setSupportUsers([]));
    }
}

function* requestFetchSupportUserDetails({ payload }) {
    try {
        yield put(ACTIONS_USERS.setUserDetails(null));
        let user = yield call(API.POST, payload.url, payload.data);
        if (!user) {
            yield put(ACTIONS_USERS.setUserDetails(null));
            yield history.goBack();
        } else {
            let supportUser = user.data.users && user.data.users.filter((user) => user.id === payload.data.id)[0];
            supportUser.name = `${supportUser.firstName} ${supportUser.lastName}`;
            supportUser.add_sites = [];
            supportUser.remove_sites = [];
            supportUser.sites
                ? supportUser.sites.map((site) => supportUser.add_sites.push(site.id))
                : (supportUser.sites = []);
            supportUser.role = supportUser.roles && supportUser.roles.filter((role) => role.tier === 1)[0];
            if (!supportUser) {
                yield history.push('/app/support/all/');
                yield put(ACTIONS_APP.showMessage(`errorCodes.description.48`, 'warning'));
            }
            supportUser ? yield put(ACTIONS_USERS.setUserDetails(supportUser)) : null;
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setUserDetails(null));
        yield history.goBack();
        console.warn(error);
    }
}

function* requestExportUsers({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.showMessage('comp.users.export.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('comp.users.export.email', 'success'));
            if (payload.push) {
                yield history.goBack();
            }
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestExportRemovedUsers({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.showMessage('comp.users.export.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('comp.users.export.email', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestSetNotifyWarning({ payload }) {
    try {
        const notify = yield call(API.POST, payload.url, payload.data);
        if (!notify) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.update', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestRemoveManagerShare({ payload }) {
    try {
        const confirm = yield call(API.POST, payload.url, payload.data);
        if (!confirm) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.managerShare', 'success'));
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchOnsiteUsers({ payload }) {
    try {
        let users = yield call(API.POST, payload.url, payload.data);
        if (!users || !users.data) {
            if (payload.map) {
                yield put(ACTIONS_USERS.setOnsiteUsers(null));
            } else {
                yield put(ACTIONS_USERS.setOnsiteUsers([]));
            }
        } else {
            if (payload.map) {
                users.data.map((user) => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    user.role = user.roles.find(
                        (role) => role.siteUUID === JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
                    );
                    user.roleName = user.role ? user.role.name : '';
                });
                let previousOnsiteUsers = yield select(getOnsiteUsers);
                if (previousOnsiteUsers) {
                    users.data.map((user) => {
                        user.previousXCoordinate = previousOnsiteUsers.find(
                            (previousUser) => user.uuid == previousUser.uuid
                        ).xCoordinate;
                        user.previousYCoordinate = previousOnsiteUsers.find(
                            (previousUser) => user.uuid == previousUser.uuid
                        ).yCoordinate;
                    });
                    yield put(ACTIONS_USERS.setOnsiteUsers(users.data));
                } else {
                    yield put(ACTIONS_USERS.setOnsiteUsers(users.data));
                }
            } else {
                users.data.map((user) => {
                    user.name = `${user.firstName} ${user.lastName}`;
                    user.role = user.roles.find(
                        (role) => role.siteUUID === JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
                    );
                    user.roleName = user.role ? user.role.name : '';
                });
                let usersFilters = yield select(getUsersFilters);
                let filters = usersFilters.find((filter) => filter.active);
                users.data = filters ? UTILS.filterArray(users.data, 'roleName', filters.value) : users.data;
                users.data = UTILS.sortList(true, users.data, 'onSiteUpdated');
                yield put(ACTIONS_USERS.setOnsiteUsers(users.data));
            }
        }
    } catch (error) {
        if (payload.map) {
            yield put(ACTIONS_USERS.setOnsiteUsers(null));
        } else {
            yield put(ACTIONS_USERS.setOnsiteUsers([]));
        }
    }
}

function* requestRemoveOnsiteUser({ payload }) {
    try {
        let user = yield call(API.POST, payload.url, payload.data);
        if (!user) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            yield put(ACTIONS_APP.showMessage('notifications.success.update', 'success'));
            yield put(ACTIONS_USERS.fetchOnsiteUsers());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchOnsiteUsersAll({ payload }) {
    try {
        let users = yield call(API.POST, payload.url, payload.data);
        if (!users || !users.data) {
            yield put(ACTIONS_USERS.setOnsiteUsersAll([]));
            let authUser = yield select(getAuthUser);
            let sites = authUser.sites ? authUser.sites : [];
            let siteList = sites.map((site) => ({ ...site, userCount: [] }));
            yield put(ACTIONS_USERS.setOnsiteUsersAll(siteList));
        } else {
            let authUser = yield select(getAuthUser);
            let sites = authUser.sites ? authUser.sites : [];
            let siteList = sites.map((site) => ({
                ...site,
                userCount: users.data.filter((user) => user.onSiteUUID == site.uuid)
            }));
            yield put(ACTIONS_USERS.setOnsiteUsersAll(siteList));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setOnsiteUsersAll([]));
    }
}

function* requestFetchManagerSites({ payload }) {
    try {
        yield put(ACTIONS_USERS.setManagerSites(null));
        let sites = yield call(API.POST, payload.url, payload.data);
        if (!sites || !sites.data || !sites.data.sites) {
            yield put(ACTIONS_USERS.setManagerSites([]));
        } else {
            sites.data.sites.forEach(s => {
                let openUnitsCount = 0;
                s.units.forEach(u => {
                    if (u.lockHwState === 'UNLOCKED' || u.lockHwState === 'UGLY' || u.lockHwState === 'JAMMED_UNLOCKING' || u.lockHwState === 'JAMMED_LOCKING') {
                        openUnitsCount += 1;
                    }
                })
                s.lowBatteryCount = s.lowBatteryUnits && s.lowBatteryUnits.length ? s.lowBatteryUnits.length : 0;
                s.openUnitsCount = openUnitsCount;
            })
            sites.data.sites = UTILS.sortList(false, sites.data.sites, 'name');
            yield put(ACTIONS_USERS.setManagerSites(sites.data.sites));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setManagerSites([]));
    }
}

function* requestFetchEmployeesOnline({ payload }) {
    try {
        let users = yield call(API.POST, payload.url, payload.data);
        if (!users || !users.data) {
            yield put(ACTIONS_USERS.setEmployeesOnline([]));
        } else {
            let authUser = yield select(getAuthUser);
            let sites = authUser.sites ? authUser.sites : [];
            users.data.employeesOnline.map((employee) => {
                employee.name = `${employee.firstName} ${employee.lastName}`;
                employee.site =
                    sites && sites.filter((site) => site.uuid == employee.defaultSiteUUID).length > 0
                        ? sites.filter((site) => site.uuid == employee.defaultSiteUUID)[0].name
                        : '';
            });
            yield put(ACTIONS_USERS.setEmployeesOnline(users.data.employeesOnline));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setEmployeesOnline([]));
    }
}

function* requestFetchLoiteringUsers({ payload }) {
    try {
        let users = yield call(API.POST, payload.url, payload.data);
        if (!users || !users.data) {
            yield put(ACTIONS_USERS.setLoiteringUsers([]));
        } else {
            users.data ? UTILS.sortList(false, users.data, 'onSiteUpdated') : [];
            yield put(ACTIONS_USERS.setLoiteringUsers(users.data));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setLoiteringUsers([]));
    }
}

function* requestFetchTransferringUsers({ payload }) {
    try {
        yield put(ACTIONS_USERS.setTransferringUsers(null));
        let users = yield call(API.POST, payload.url, payload.data);
        if (!users || !users.data || !users.data.users) {
            yield put(ACTIONS_USERS.setTransferringUsers([]));
        } else {
            const transferHeaders = yield select(getTransferHeaders);
            const activeHeader = transferHeaders.find((item) => item.active);
            users.data.users = UTILS.sortList(!activeHeader.order, users.data.users, activeHeader.sortTitle);
            yield put(ACTIONS_USERS.setTransferringUsers(users.data.users));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setTransferringUsers([]));
    }
}

function* requestExtendUserTransfer({ payload }) {
    try {
        let user = yield call(API.POST, payload.url, payload.data);
        if (!user) {
            yield put(ACTIONS_APP.showMessage('notifications.error.failed', 'warning'));
            return;
        } else {
            let authUser = yield select(getAuthUser);
            let site = yield select(getSite);
            let tomorrow = UTILS.dateFormatter(moment().add(1, 'days'), authUser, site)
            yield put(ACTIONS_APP.showMessage(`${payload.intl.formatMessage({ id: 'comp.button.transfer.extendNotification' })} ${tomorrow}`, 'success'));
            yield put(ACTIONS_USERS.fetchTransferringUsers());
        }
    } catch (error) {
        console.warn(error);
    }
}

function* requestFetchTenantUnits({ payload }) {
    try {
        yield put(ACTIONS_USERS.setTenantUnits(null));
        let units = yield call(API.POST, payload.url, payload.data);
        if (!units || !units.data || !units.data.units) {
            yield put(ACTIONS_USERS.setTenantUnits([]));
        } else {
            yield put(ACTIONS_USERS.setTenantUnits(units.data.units));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setTenantUnits([]));
    }
}

function* requestFetchTenantShares({ payload }) {
    try {
        yield put(ACTIONS_USERS.setTenantShares(null));
        let shares = yield call(API.POST, payload.url, payload.data);
        if (!shares || !shares.data || !shares.data.shares) {
            yield put(ACTIONS_USERS.setTenantShares([]));
        } else {
            yield put(ACTIONS_USERS.setTenantShares(shares.data.shares));
        }
    } catch (error) {
        yield put(ACTIONS_USERS.setTenantShares([]));
    }
}

export function* selectSendUserActivation() {
    yield takeEvery(CONSTANTS.SEND_USER_ACTIVATION, requestConfirmation);
}

export function* selectSendUserActivationAll() {
    yield takeEvery(CONSTANTS.SEND_USER_ACTIVATION_ALL, requestConfirmationAll);
}

export function* selectSetNotifyWarning() {
    yield takeEvery(CONSTANTS.SET_NOTIFY_WARNING, requestSetNotifyWarning);
}

export function* selectUnfreezeUserAccount() {
    yield takeEvery(CONSTANTS.UNFREEZE_USER_ACCOUNT, requestConfirmation);
}

export function* selectRefreshUserPin() {
    yield takeEvery(CONSTANTS.REFRESH_USER_PIN, requestConfirmation);
}

export function* selectFetchUserLoginAttempts() {
    yield takeEvery(CONSTANTS.FETCH_USER_LOGIN_ATTEMPTS, requestLoginAttempts);
}

export function* selectFetchUserPin() {
    yield takeEvery(CONSTANTS.FETCH_USER_PIN, requestUserPin);
}

export function* selectSaveUserSites() {
    yield takeEvery(CONSTANTS.SAVE_USER_SITES, requestConfirmation);
}

export function* selectFetchUserFobs() {
    yield takeEvery(CONSTANTS.FETCH_USER_FOBS, requestUserFobs);
}

export function* selectFetchUserShares() {
    yield takeEvery(CONSTANTS.FETCH_USER_SHARES, requestUserShares);
}

export function* selectCreateUser() {
    yield takeEvery(CONSTANTS.CREATE_USER, requestUserCreate);
}

export function* selectCreateServiceUser() {
    yield takeEvery(CONSTANTS.CREATE_SERVICE_USER, requestServiceUserCreate);
}

export function* selectDeleteUser() {
    yield takeEvery(CONSTANTS.DELETE_USER, requestUserDelete);
}

export function* selectDeleteServiceUser() {
    yield takeEvery(CONSTANTS.DELETE_SERVICE_USER, requestUserDelete);
}

export function* selectEditUser() {
    yield takeEvery(CONSTANTS.EDIT_USER, requestUserEdit);
}

export function* selectEditUserRole() {
    yield takeEvery(CONSTANTS.EDIT_USER_ROLE, requestUserEditRole);
}

export function* selectEditUserPermissions() {
    yield takeEvery(CONSTANTS.EDIT_USER_PERMISSION, requestUserEditPermissions);
}

export function* selectEditUserExpiration() {
    yield takeEvery(CONSTANTS.EDIT_USER_EXPIRATION, requestUserEditUserExpiration);
}

export function* selectFetchUserGatePermissions() {
    yield takeEvery(CONSTANTS.SAVE_USER_GATE_PERMISSIONS, requestConfirmation);
}

export function* selectEditUserPhone() {
    yield takeEvery(CONSTANTS.EDIT_USER_PHONE, requestConfirmation);
}

export function* selectFetchSiteUserList() {
    yield takeEvery(CONSTANTS.FETCH_SITE_USER_LIST, requestSiteUserList);
}

export function* selectFetchRemovedUsers() {
    yield takeEvery(CONSTANTS.FETCH_REMOVED_USERS, requestRemovedUsers);
}

export function* selectFetchUserDetails() {
    yield takeEvery(CONSTANTS.FETCH_USER_DETAILS, requestUserDetails);
}

export function* selectFetchUserPhoneDetails() {
    yield takeEvery(CONSTANTS.FETCH_USER_PHONE_DETAILS, requestUserPhoneDetails);
}

export function* selectConfirmUserAccount() {
    yield takeEvery(CONSTANTS.CONFIRM_USER_ACCOUNT, requestConfirmation);
}

export function* selectRemoveUserFob() {
    yield takeEvery(CONSTANTS.REMOVE_USER_FOB, requestConfirmation);
}

export function* selectSaveUserDailyHours() {
    yield takeEvery(CONSTANTS.SAVE_USER_DAILY_HOURS, requestSaveUserDailyHours);
}

export function* selectDeleteUserDailyHours() {
    yield takeEvery(CONSTANTS.DELETE_USER_DAILY_HOURS, requestDeleteUserDailyHours);
}

export function* selectRemoveOnsiteUser() {
    yield takeEvery(CONSTANTS.REMOVE_ONSITE_USER, requestRemoveOnsiteUser);
}

export function* selectUserAcknowledgeTraining() {
    yield takeEvery(CONSTANTS.USER_ACKNOWLEDGE_TRAINING, requestUserAcknowledgeTraining);
}

export function* selectFetchUserUnits() {
    yield takeEvery(CONSTANTS.FETCH_USER_UNITS, requestFetchUserUnits);
}

export function* selectSendUserAccesscode() {
    yield takeEvery(CONSTANTS.SEND_USER_ACCESSCODE, requestSendUserAccesscode);
}

export function* selectEditUserSettings() {
    yield takeEvery(CONSTANTS.EDIT_USER_SETTINGS, requestEditUserSettings);
}

export function* selectEdit2FactorSelf() {
    yield takeEvery(CONSTANTS.EDIT_SELF_2FACTOR, requestEdit2FactorSelf);
}

export function* selectEdit2Factor() {
    yield takeEvery(CONSTANTS.EDIT_2FACTOR, requestEdit2Factor);
}

export function* selectFetchSupportUserList() {
    yield takeEvery(CONSTANTS.FETCH_SUPPORT_USERS, requestFetchSupportUserList);
}

export function* selectFetchSupportUserDetails() {
    yield takeEvery(CONSTANTS.FETCH_SUPPORT_USER_DETAILS, requestFetchSupportUserDetails);
}

export function* selectExportUsers() {
    yield takeEvery(CONSTANTS.EXPORT_USERS, requestExportUsers);
}

export function* selectExportRemovedUsers() {
    yield takeEvery(CONSTANTS.EXPORT_REMOVED_USERS, requestExportRemovedUsers);
}

export function* selectRemoveManagerShare() {
    yield takeEvery(CONSTANTS.REMOVE_MANAGER_SHARE, requestRemoveManagerShare);
}

export function* selectFetchOnsiteUsers() {
    yield takeEvery(CONSTANTS.FETCH_ONSITE_USERS, requestFetchOnsiteUsers);
}

export function* selectEditUserEmail() {
    yield takeEvery(CONSTANTS.EDIT_USER_EMAIL, requestUserEdit);
}

export function* selectEditUserKeyUse() {
    yield takeEvery(CONSTANTS.EDIT_USER_KEY_USE, requestUserEdit);
}

export function* selectEditUserView() {
    yield takeEvery(CONSTANTS.EDIT_USER_VIEW, requestUserEdit);
}

export function* selectFetchOnsiteUsersAll() {
    yield takeEvery(CONSTANTS.FETCH_ONSITE_USERS_ALL, requestFetchOnsiteUsersAll);
}

export function* selectFetchManagerSites() {
    yield takeEvery(CONSTANTS.FETCH_MANAGER_SITES, requestFetchManagerSites);
}

export function* selectFetchEmployeesOnline() {
    yield takeEvery(CONSTANTS.FETCH_EMPLOYEES_ONLINE, requestFetchEmployeesOnline);
}

export function* selectFetchLoiteringUsers() {
    yield takeEvery(CONSTANTS.FETCH_LOITERING_USERS, requestFetchLoiteringUsers);
}

export function* selectFetchTransferringUsers() {
    yield takeEvery(CONSTANTS.FETCH_TRANSFERRING_USERS, requestFetchTransferringUsers);
}

export function* selectExtendUserTransfer() {
    yield takeEvery(CONSTANTS.EXTEND_USER_TRANSFER, requestExtendUserTransfer);
}

export function* selectFetchTenantUnits() {
    yield takeEvery(CONSTANTS.FETCH_TENANT_UNITS, requestFetchTenantUnits);
}

export function* selectFetchTenantShares() {
    yield takeEvery(CONSTANTS.FETCH_TENANT_SHARES, requestFetchTenantShares);
}

export default function* rootSaga() {
    yield all([
        fork(selectSendUserActivation),
        fork(selectSendUserActivationAll),
        fork(selectUnfreezeUserAccount),
        fork(selectRefreshUserPin),
        fork(selectFetchUserPin),
        fork(selectFetchUserLoginAttempts),
        fork(selectFetchUserFobs),
        fork(selectFetchUserShares),
        fork(selectSaveUserSites),
        fork(selectCreateUser),
        fork(selectCreateServiceUser),
        fork(selectDeleteUser),
        fork(selectDeleteServiceUser),
        fork(selectRemoveUserFob),
        fork(selectEditUser),
        fork(selectEditUserRole),
        fork(selectEditUserPhone),
        fork(selectEditUserPermissions),
        fork(selectEditUserExpiration),
        fork(selectFetchUserGatePermissions),
        fork(selectFetchSiteUserList),
        fork(selectFetchUserDetails),
        fork(selectFetchUserPhoneDetails),
        fork(selectConfirmUserAccount),
        fork(selectSaveUserDailyHours),
        fork(selectDeleteUserDailyHours),
        fork(selectUserAcknowledgeTraining),
        fork(selectFetchUserUnits),
        fork(selectSendUserAccesscode),
        fork(selectEditUserSettings),
        fork(selectEdit2FactorSelf),
        fork(selectEdit2Factor),
        fork(selectFetchSupportUserList),
        fork(selectFetchSupportUserDetails),
        fork(selectExportUsers),
        fork(selectExportRemovedUsers),
        fork(selectSetNotifyWarning),
        fork(selectRemoveManagerShare),
        fork(selectFetchOnsiteUsers),
        fork(selectEditUserEmail),
        fork(selectEditUserKeyUse),
        fork(selectEditUserView),
        fork(selectRemoveOnsiteUser),
        fork(selectFetchRemovedUsers),
        fork(selectFetchOnsiteUsersAll),
        fork(selectFetchManagerSites),
        fork(selectFetchEmployeesOnline),
        fork(selectFetchLoiteringUsers),
        fork(selectFetchTransferringUsers),
        fork(selectExtendUserTransfer),
        fork(selectFetchTenantUnits),
        fork(selectFetchTenantShares),
    ]);
}