import React, { Suspense } from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { isIOS, isMobile } from 'react-device-detect';
import { StyleSheet, css } from 'aphrodite';
import { fadeInDown } from 'react-animations';
import { ErrorBoundary } from 'react-error-boundary';

import { fetchStartup, showMessage } from 'actions/App';
import { userSignOut } from 'actions/Auth';

import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants';
import * as CONSTANTS from 'constants';

import Header from 'components/Header';
//import SimpleHeader from 'components/Header/simpleView';
import Menu from 'components/Menus/sidebar';
import Spinner from 'components/Spinner';
import PortalSearch from 'components/PortalSearch';
import { ErrorFallback } from 'components/ErrorFallback';

const Dashboard = React.lazy(() => import('app/routes/Dashboard'));
const SimplifiedView = React.lazy(() => import('app/routes/SimplifiedView'));
const TenantView = React.lazy(() => import('app/routes/TenantView'));
const Units = React.lazy(() => import('app/routes/Units'));
const Users = React.lazy(() => import('app/routes/Users'));
const FacilityWizard = React.lazy(() => import('app/routes/Site'));
const CompanyWizard = React.lazy(() => import('app/routes/Company'));
const Entry = React.lazy(() => import('app/routes/Entry'));
const Feedback = React.lazy(() => import('app/routes/Feedback'));
const AccessCodes = React.lazy(() => import('app/routes/AccessCodes'));
const Network = React.lazy(() => import('app/routes/Gateways'));
const Sites = React.lazy(() => import('app/routes/Sites'));
const Reports = React.lazy(() => import('app/routes/Reports'));
const UserSites = React.lazy(() => import('app/routes/UserSites'));
const Activity = React.lazy(() => import('app/routes/Activity'));
const Settings = React.lazy(() => import('app/routes/Settings'));
const FleetMgmt = React.lazy(() => import('app/routes/FleetMgmt'));
const Hours = React.lazy(() => import('app/routes/Hours'));
const Maps = React.lazy(() => import('app/routes/Map'));
const Notifications = React.lazy(() => import('app/routes/Notifications'));
const Help = React.lazy(() => import('app/routes/Help'));
const Instructions = React.lazy(() => import('app/routes/Instructions'));
const Releases = React.lazy(() => import('app/routes/Releases'));
const Troubleshoot = React.lazy(() => import('app/routes/Troubleshoot'));
const Cameras = React.lazy(() => import('app/routes/Cameras'));
const Notes = React.lazy(() => import('app/routes/SiteNotes'));
const Contact = React.lazy(() => import('app/routes/ContactTenant'));
const Testing = React.lazy(() => import('app/routes/Testing'));
const Logout = React.lazy(() => import('app/routes/Auth/routes/Logout'));
const NoPermissions = React.lazy(() => import('components/Error403'));
class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showNotification: true
        };
    }
    componentDidMount() {
        const { authUser, fetchStartup, site } = this.props;
        const { showNotification } = this.state;
        // if the local storage was cleared for whatever reason we DO need access to this particular object in it throughout the app
        // so we'll just kick them and make them re login to make sure we have that at the ready
        if (!localStorage.getItem('nse_login_data')) {
            this.props.userSignOut();
            return;
        }
        let siteUUID = JSON.parse(localStorage.getItem('nse_login_data')).site
            ? JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
            : site && site.uuid;
        let newSite = siteUUID && authUser.sites.find((site) => site.uuid == siteUUID);
        fetchStartup(authUser, newSite);
        CONSTANTS.FIREBASE_NOTIFICATIONS_MESSAGING &&
            CONSTANTS.FIREBASE_NOTIFICATIONS_MESSAGING.onMessage((payload) => {
                showNotification &&
                    payload &&
                    payload.notification &&
                    this.props.showMessage(payload.notification.body, 'note', `${payload.notification.title}:`);
            });
    }
    render() {
        const { authUser, match, drawerType, width,showPortalSearch } = this.props;
        this.styles = styles();

        const drawerStyle = drawerType.includes(FIXED_DRAWER)
            ? 'fixed-drawer'
            : drawerType.includes(COLLAPSED_DRAWER)
            ? 'collapsible-drawer'
            : 'mini-drawer';
        const permissions = authUser && authUser.permissions;

        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height');
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height');
        }

        return (
            <div className={`app-container ${drawerStyle}`}>
                {((authUser && !authUser.simplifiedView)) && authUser.role && authUser.role.tier && authUser.role.tier > 0 ? <Menu /> : null}
                <div className="app-main-container">
                    <div className={`app-header ${css(this.styles.fadeInDown)}`}>
                        <>
                            <Header />
                            {width > 600 && showPortalSearch && <PortalSearch />}
                        </>
                    </div>
                    <main className="app-main-content-wrapper">
                        <div className="app-main-content">
                            <ErrorBoundary FallbackComponent={ErrorFallback}>
                                <Suspense fallback={<Spinner fallback={true} />}>
                                    {authUser &&
                                    authUser.role &&
                                    authUser.role.tier === 0 ? (
                                        <Switch>
                                            <Route path={`${match.url}/home`} component={TenantView} />
                                            <Route path={`${match.url}/users`} component={Users} />
                                            <Route path={`${match.url}/logout`} component={Logout} />
                                            <Route path={`${match.url}/user/facilities`} component={UserSites} />
                                            <Route path={`${match.url}/tenant/contact`} component={Contact} />
                                            {permissions.includes('support_view_all_companies') ? (
                                                <Route path={`${match.url}/facilities`} component={Sites} />
                                            ) : (
                                                <Route path={`${match.url}/facilities`} component={NoPermissions} />
                                            )}
                                            <Route component={SimplifiedView} />
                                        </Switch>
                                    ) : 
                                    authUser &&
                                    authUser.simplifiedView
                                    ? (
                                        <Switch>
                                            <Route path={`${match.url}/home`} component={SimplifiedView} />
                                            <Route path={`${match.url}/users`} component={Users} />
                                            <Route path={`${match.url}/hours`} component={Hours} />
                                            <Route path={`${match.url}/logout`} component={Logout} />
                                            <Route path={`${match.url}/user/facilities`} component={UserSites} />
                                            <Route path={`${match.url}/notifications`} component={Notifications} />
                                            <Route path={`${match.url}/feedback`} component={Feedback} />
                                            {permissions.includes('support_view_all_companies') ? (
                                                <Route path={`${match.url}/facilities`} component={Sites} />
                                            ) : (
                                                <Route path={`${match.url}/facilities`} component={NoPermissions} />
                                            )}
                                            <Route component={SimplifiedView} />
                                        </Switch>
                                    ) : (
                                        <Switch>
                                            <Route path={`/dashboard`} component={Dashboard} />
                                            <Route path={`${match.url}/home`} component={Dashboard} />
                                            <Route path={`${match.url}/units`} component={Units} />
                                            <Route path={`${match.url}/users`} component={Users} />
                                            <Route path={`${match.url}/entry`} component={Entry} />
                                            <Route path={`${match.url}/facility`} component={FacilityWizard} />
                                            <Route path={`${match.url}/company`} component={CompanyWizard} />
                                            <Route path={`${match.url}/feedback`} component={Feedback} />
                                            {permissions.includes('support_get_access_codes') ? (
                                                <Route path={`${match.url}/accessCodes`} component={AccessCodes} />
                                            ) : (
                                                <Route path={`${match.url}/accessCodes`} component={NoPermissions} />
                                            )}
                                            {permissions.includes('manage_gateways') ? (
                                                <Route path={`${match.url}/network`} component={Network} />
                                            ) : (
                                                <Route path={`${match.url}/network`} component={NoPermissions} />
                                            )}
                                            {permissions.includes('support_view_all_companies') ? (
                                                <Route path={`${match.url}/facilities`} component={Sites} />
                                            ) : (
                                                <Route path={`${match.url}/facilities`} component={NoPermissions} />
                                            )}
                                            {permissions.includes('view_suspicious') ? (
                                                <Route path={`${match.url}/reports`} component={Reports} />
                                            ) : (
                                                <Route path={`${match.url}/reports`} component={NoPermissions} />
                                            )}
                                            {permissions.includes('view_security_cameras') ? (
                                                <Route path={`${match.url}/cameras`} component={Cameras} />
                                            ) : (
                                                <Route path={`${match.url}/cameras`} component={NoPermissions} />
                                            )}
                                            <Route path={`${match.url}/user/facilities`} component={UserSites} />
                                            <Route path={`${match.url}/activity`} component={Activity} />
                                            <Route path={`${match.url}/settings`} component={Settings} />
                                            <Route path={`${match.url}/fleetmgmt`} component={FleetMgmt} />
                                            <Route path={`${match.url}/hours`} component={Hours} />
                                            <Route path={`${match.url}/maps`} component={Maps} />
                                            <Route path={`${match.url}/notifications`} component={Notifications} />
                                            <Route path={`${match.url}/help`} component={Help} />
                                            <Route path={`${match.url}/instructions`} component={Instructions} />
                                            <Route path={`${match.url}/releases`} component={Releases} />
                                            <Route path={`${match.url}/testing`} component={Testing} />
                                            {permissions.includes('support_view_site_notes') ? (
                                                <Route path={`${match.url}/notes`} component={Notes} />
                                            ) : (
                                                <Route path={`${match.url}/notes`} component={NoPermissions} />
                                            )}
                                            {permissions.includes('support_view_all_companies') ? (
                                                <Route path={`${match.url}/troubleshoot`} component={Troubleshoot} />
                                            ) : (
                                                <Route path={`${match.url}/troubleshoot`} component={NoPermissions} />
                                            )}
                                            <Route path={`${match.url}/logout`} component={Logout} />
                                            <Route component={Dashboard} />
                                        </Switch>
                                    )}
                                </Suspense>
                            </ErrorBoundary>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, app }) => {
    const { authUser, site } = auth;
    const { drawerType, width, showPortalSearch } = app;
    return { authUser, drawerType, width, showPortalSearch, site };
};
export default withRouter(
    connect(mapStateToProps, {
        fetchStartup,
        showMessage,
        userSignOut
    })(App)
);

const styles = () =>
    StyleSheet.create({
        fadeInDown: {
            animationName: fadeInDown,
            animationDuration: '1.2s'
        }
    });
