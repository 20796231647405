import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { UnitIcon } from 'utilities/icons';

import Header from 'components/Header/dashboard';
import Table from 'components/Table'
import LowBatteryCell from 'components/Table/cell/containers/units/lowBattery';
class DashboardLowBattery extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lowBatteryHeaders: 
            [
                {
                    id: 0,
                    title: 'blank',
                    active: false,
                    sortable: false,
                    order: false,
                    mobile: false,
                    width: 45
                },
                {
                    id: 1,
                    title: 'comp.header.locks',
                    active: true,
                    sortable: true,
                    order: true,
                    mobile: true,
                    sortTitle: 'lockName',
                    style: { textAlign: 'left' }
                },
                {
                    id: 2,
                    title: 'comp.table.cell.initiallyReportedHeader',
                    active: false,
                    sortable: true,
                    order: true,
                    mobile: true,
                    sortTitle: 'initallyReported',
                    style: { textAlign: 'center' }
                },
                {
                    id: 3,
                    title: 'comp.table.cell.batteryState',
                    active: false,
                    sortable: true,
                    order: true,
                    mobile: true,
                    sortTitle: 'batteryState'
                }
            ]
        };
    }
    render() {
        const { theme, width, lowBatteryUnits, newBatteryEndpointNotExist, authUser } = this.props;
        const { lowBatteryHeaders } = this.state
        return (
            <>
                <Header
                    Icon={UnitIcon}
                    iconSize={26}
                    title={width > 1404 ? 'comp.table.header.lowBatteryUnits' : 'comp.table.header.lowBatteryUnit'}
                    manage={
                        newBatteryEndpointNotExist || (authUser && authUser.permissions && !authUser.permissions.includes('manage_locks'))
                        ?
                        null :
                        [
                            {
                                icon: 'view_list',
                                color: theme.color.buttonFontColor,
                                text: 'comp.table.cell.viewDetails',
                                action: () => this.props.history.push('/app/units/lowbattery'),
                                id: 'lowBattery-view_list'
                            }
                        ]}
                />
                <Row style={{ margin: 0, padding: '0 20px 20px 20px' }}>
                    <Table
                        tableHeight={100}
                        data={lowBatteryUnits}
                        header={lowBatteryHeaders}
                        Cell={LowBatteryCell}
                        itemSize={50}
                    />
                </Row>

                
            </>
        );
    }
}

const mapStateToProps = ({ app, auth, units }) => {
    const { theme, width } = app;
    const { authUser, site } = auth;
    const { lowBatteryUnits, newBatteryEndpointNotExist } = units;
    return { theme, width, authUser, site, lowBatteryUnits, newBatteryEndpointNotExist };
};

export default withRouter(injectIntl(connect(mapStateToProps)(DashboardLowBattery)));
