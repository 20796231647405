import React from 'react';
class CellBlank extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { Cellwidth } = this.props;
        return <td width={Cellwidth}></td>;
    }
}

export default CellBlank;
