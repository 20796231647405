import axios from 'axios';
import axiosRetry from 'axios-retry';
import { store } from '../index.js';
import { history } from '../store/history';
import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';
import { getLang } from '../lngProvider';
import { createIntl } from 'react-intl';
import moment from 'moment';
import packageJson from '../../package.json';
import { osName, osVersion, browserName, browserVersion } from 'react-device-detect';

axios.interceptors.response.use(async (response) => {
    // Need to account for 2 cases here where we will retry the endpoint ONCE and only once. If it fails a second time well thats just going to result in our error handler catching that
    // #1 is if response.status is 200, but response.data.message is Internal Server Error (This seems to happen if server is overloaded or had some trouble processing the request or something)
    // #2 is if the response.data is just flat out null (Not sure why this one happens, but sometimes it does and we're gonna try again just in case)
    if ((response.status == 200 && response.data && response.data.message && response.data.message == 'Internal server error') || (response.message && response.message == 'Internal server error')) {
        if (!response.config.retried) {
            store.dispatch({
                type: CONSTANTS.SUBMIT_ERROR_DETAILS,
                payload: {
                    url: '/error/submit/',
                    data: {
                        type: "Server",
                        code: 10,
                        subcode: 1,
                        timeStamp: moment().utc().format(),
                        version: packageJson.version ? packageJson.version : null,
                        OS: osName && osVersion ? `${osName} ${osVersion}` : null,
                        environment: browserName && browserVersion ? `${browserName} ${browserVersion}` : null,
                        userUUID: sessionStorage.getItem('user_data')
                            ? JSON.parse(sessionStorage.getItem('user_data')).uuid
                            : null,
                        siteUUID: localStorage.getItem('nse_login_data')
                            ? JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
                            : null,
                        claimsCompanyUUID: localStorage.getItem('nse_login_data')
                            ? JSON.parse(localStorage.getItem('nse_login_data')).companyUUID
                            : null,
                        url: window.location ? window.location.href : null,
                        endpoint: response.data && response.data.request ? response.data.request : "",
                        jsonSent: "",
                        jsonReceived: response.data ? JSON.stringify(response.data) : "",
                        userNotes: ""
                    },
                    autoSubmit: true
                }
            });
        }
        const err = new Error("retrying");
        err.config = response.config; // axios-retry using this
        throw err;
    }
    if (response.data === null) {
        if (!response.config.retried) {
            store.dispatch({
                type: CONSTANTS.SUBMIT_ERROR_DETAILS,
                payload: {
                    url: '/error/submit/',
                    data: {
                        type: "Server",
                        code: 11,
                        subcode: 1,
                        timeStamp: moment().utc().format(),
                        version: packageJson.version ? packageJson.version : null,
                        OS: osName && osVersion ? `${osName} ${osVersion}` : null,
                        environment: browserName && browserVersion ? `${browserName} ${browserVersion}` : null,
                        userUUID: sessionStorage.getItem('user_data')
                            ? JSON.parse(sessionStorage.getItem('user_data')).uuid
                            : null,
                        siteUUID: localStorage.getItem('nse_login_data')
                            ? JSON.parse(localStorage.getItem('nse_login_data')).siteUUID
                            : null,
                        claimsCompanyUUID: localStorage.getItem('nse_login_data')
                            ? JSON.parse(localStorage.getItem('nse_login_data')).companyUUID
                            : null,
                        url: window.location ? window.location.href : null,
                        endpoint: response.config && response.config.url ? response.config.url.replace(/(?:.*?\/){3}/, "") : "",
                        jsonSent: "",
                        jsonReceived: "",
                        userNotes: ""
                    },
                    autoSubmit: true
                }
            });
        }
        const err = new Error("retrying");
        err.config = response.config; // axios-retry using this
        throw err;
    }
    return response;
});
  
axiosRetry(axios, {
    retries: 1,
    onRetry: (retryCount, error, requestConfig) => {
        requestConfig.retried = true;
        console.warn('axios retried request', 'requestConfig: ', requestConfig);
    },
    shouldResetTimeout: true,
    retryCondition: (error) => {
        return true;
    }
});

let currentOutage = JSON.parse(sessionStorage.getItem('current_outage'));
let pendingOutage = JSON.parse(sessionStorage.getItem('pending_outage'));
const CancelToken = axios.CancelToken;
let cancel;
const local = localStorage.getItem('nse_local') || 'en';
let intl; // gets set in init

const init = async () => {
    const data = await getLang(local);
    intl = data && data.default && createIntl({
        locale: data.default.locale,
        messages: data.default.messages,
    })
};

init();

history.listen((route, method) => {
    if (cancel && route.pathname.includes('display') && method !== 'POP') cancel('Operation canceled because the route changed');
})

//=====================================
// URL CHECKER
//=====================================
const URL_CHECKER = () => {
    let URL_SET = sessionStorage.getItem('nse_url');
    if (!URL_SET) {
        // localhost will default to dev router
        // if it is aws.noke url we need to make sure to pre-pend nse-router
        // otherwise we just need to pre-pend router.
        let URL = window.location.hostname.includes('localhost')
        ?
        "https://router.smartentry.noke.dev"
        :
        window.location.hostname.includes('aws.noke.')
        ?
        `https://${window.location.hostname.replace('frontend', 'nse-router')}`
        :
        "https://router." + window.location.hostname;
        sessionStorage.setItem('nse_url', URL);
        return URL;
    } else {
        return URL_SET;
    }
};
const URL_CHECKER_GATEWAY_KEEPER = () => {
    let URL_SET = sessionStorage.getItem('nse_url');
    if (!URL_SET) {
        let URL = `https://gateway-keeper-dot-extraspace${CONSTANTS.ENV}-go.appspot.com`;
        return URL;
    } else {
        return URL_SET === 'https://router.smartentry.noke.com'
            ? `https://router.smartentry.noke.com`
            : URL_SET === 'https://router.smartentry.noke.dev'
            ? `https://router.smartentry.noke.dev`
            : URL_SET === 'https://router.smartentry.noke.run'
            ? `https://router.smartentry.noke.run`
            : URL_SET === ENDPOINTS.URL_LIVE
            ? ENDPOINTS.URL_GATEWAY_KEEPER_PROD
            : ENDPOINTS.URL_GATEWAY_KEEPER_DEV;
    }
};
const API_CHECKER_GATEWAY_KEEPER = () => {
    let URL_SET = sessionStorage.getItem('nse_url');
    if (!URL_SET) {
        return ENDPOINTS.API_GATEWAY_KEEPER;
    } else {
        return URL_SET === ENDPOINTS.URL_LIVE ? ENDPOINTS.API_GATEWAY_KEEPER_PROD : ENDPOINTS.API_GATEWAY_KEEPER_DEV;
    }
};

//=====================================
// FAILED
//=====================================
const FAILED = (res, req, endpoint) => {
    if (res.data.errorCode === 9) {
        // grab url before kicking them so we can take them back upon successful re-login
        let url = window.location.href.split('#/').slice(1);
        url && url != 'signin' && localStorage.setItem('token_expiration_url', url);
        window.location.reload();
        store.dispatch({
            type: CONSTANTS.SIGNOUT_USER
        });
        return null;
    } else {
        store.dispatch({
            type: CONSTANTS.FETCH_ERROR_DETAILS,
            payload: {
                url: '/error/read/',
                data: {
                    type: `${res.data.type}`,
                    code: Number(`${res.data.errorCode}`),
                    subcode: Number(`${res.data.subcode}`)
                },
                jsonReceived: JSON.stringify(res.data),
                jsonSent: req ? JSON.stringify(req) : JSON.stringify({}),
                endpoint: endpoint ? endpoint : null,
                errorID: res.data.errorID ? res.data.errorID : null
            }
        });
        if (endpoint === '/login/' || endpoint === '/login/validate/') {
            let isValid = '0';
            let success = 0;
            if (res.data.message.includes('An unexpected error has occurred. We apologize for the inconvenience. Please try again later. If the problem persists, please contact facility management.') ||
                res.data.message.includes('Internet connection not detected, please check your internet settings and try again.') ||
                res.data.message.includes('The request timed out, please try again')
            ) {
                isValid = '1';
            }
            store.dispatch({
                type: CONSTANTS.SUBMIT_LOGIN_PERFORMANCE_FAILED,
                payload: {
                    url: '/performance/login/failed/',
                    data: {
                        ...store.getState().app.loginPerformance,
                        login_api_end_time: moment().utc(),
                        start_time: store.getState().app.loginPerformance.start_time.utc(),
                        overall_time: moment().diff(moment(store.getState().app.loginPerformance.start_time), 'milliseconds'),
                        site_id: req.siteUUID ? parseInt(req.siteUUID) : 0,
                        failure_reason: `${res.data.message}${res.data.data ? ' - ' + res.data.data : ''}`,
                        end_time: moment().utc(),
                        success: success,
                        isValid: isValid
                    },
                }
            });
        }
        store.dispatch({
            type: CONSTANTS.SET_LOGIN_PERFORMANCE,
            payload: {
                site_id: 0,
                platform: 'Portal',
                userId: 0,
                loginType: '',
                start_time: null,
                login_api_end_time: null,
                overall_time: 0,
                end_time: null,
                failure_reason: '',
                isOnline: 1,
                success: 0,
                isValid: '0',
            }
        });
        return null;
    }
};
const FAILED_ERROR = (error) => {
    if (error != 'Error: retrying') {
        console.warn('FAILED ERROR: ', error);
        store.dispatch({
            type: CONSTANTS.SHOW_MESSAGE,
            payload: {
                message: error.name == 'AxiosError' ? `${error.message}` : `${error}`,
                type: 'message-error'
            }
        });
    }
    return null;
};
const FAILED_WARN = (error) => {
    console.warn('FAILED ERROR: ', error);
    return null;
};
const FAILED_REPORTS = (res) => {
    if (res.errorCode === 9) {
        store.dispatch({
            type: CONSTANTS.SIGNOUT_USER
        });
        return null;
    } else {
        store.dispatch({
            type: CONSTANTS.SHOW_MESSAGE,
            payload: {
                message: res.message,
                type: 'message'
            }
        });
        return null;
    }
};

//=====================================
// LOGIN
//=====================================
export const LOGIN = async (url, data, payload) =>
    await axios
        .post(URL_CHECKER() + url, data)
        .then((res) => {
            if (!res.data){
                return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.10' }));
            } else if (res.data.outageOn && !currentOutage) {
                sessionStorage.setItem('current_outage', true);
            } else if (!res.data.outageOn && currentOutage) {
                sessionStorage.setItem('current_outage', false);
            }
            if (res.data.outagePending && !pendingOutage) {
                sessionStorage.setItem('pending_outage', true);
                sessionStorage.setItem('outage_time', res.data.outageAt);
            } else if (!res.data.outagePending && pendingOutage) {
                sessionStorage.setItem('pending_outage', false);
            }
            if (res.data.result === 'failure') {
                if (res.data.errorCode === 103) {
                    data.phoneLogin = payload.data.phoneLogin;
                    history.push({
                        pathname: '/company',
                        state: { login: data, companies: res.data.data.CompanyList }
                    });
                } else {
                    return FAILED(res, data, url);
                }
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// LOGIN TEST - used for the testing suite
//=====================================
export const LOGIN_TEST = async (url, data, payload) =>
    await axios
        .post(URL_CHECKER() + url, data)
        .then((res) => {
            if (!res.data){
                return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.10' }));
            } else if (res.data.outageOn && !currentOutage) {
                sessionStorage.setItem('current_outage', true);
            } else if (!res.data.outageOn && currentOutage) {
                sessionStorage.setItem('current_outage', false);
            }
            if (res.data.outagePending && !pendingOutage) {
                sessionStorage.setItem('pending_outage', true);
                sessionStorage.setItem('outage_time', res.data.outageAt);
            } else if (!res.data.outagePending && pendingOutage) {
                sessionStorage.setItem('pending_outage', false);
            }
            return res.data;
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// RESET
//=====================================
export const RESET = async (url, data, token) =>
    await axios
        .post(URL_CHECKER() + url, data, { headers: { Authorization: 'Bearer ' + token } })
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED(res, data, url);
            } else {
                return res;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// COUNTRY
//=====================================
export const COUNTRY = async () =>
    await axios
        .post(ENDPOINTS.URL_IP_LOCATION)
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED(res);
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// POST
//=====================================
export const POST = async (endpoint, data) => {
    (await endpoint) == '/site/switch/' && cancel('Operation canceled by the user');
    return await axios
        .post(URL_CHECKER() + endpoint, data, {
            headers: { Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user_data')).token },
            cancelToken: new CancelToken(function executor(c) {
                endpoint !== '/site/switch/' ? (cancel = c) : null;
            })
        })
        .then((res) => {
            if (!res.data){
                return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.10' }));
            } else if (res.data.outageOn && !currentOutage) {
                sessionStorage.setItem('current_outage', true);
            } else if (!res.data.outageOn && currentOutage) {
                sessionStorage.setItem('current_outage', false);
            }
            if (res.data.outagePending && !pendingOutage) {
                sessionStorage.setItem('pending_outage', true);
                sessionStorage.setItem('outage_time', res.data.outageAt);
            } else if (!res.data.outagePending && pendingOutage) {
                sessionStorage.setItem('pending_outage', false);
            }
            if (res.data.result === 'failure') {
                if (res.data.message === 'Work done, but could not send text and/or email to the user.') {
                    let userData = JSON.parse(sessionStorage.getItem('user_data'))
                        ? JSON.parse(sessionStorage.getItem('user_data'))
                        : null;
                    userData && userData.token && res.data.token && userData.token !== res.data.token
                        ? (userData.token = res.data.token)
                        : '';
                    sessionStorage.setItem('user_data', JSON.stringify(userData));
                    return res.data;
                } else if (res.data.message === 'User already exists.') {
                    return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.14' }));
                } else if (res.data.message === 'User already has a role for the current site.') {
                    return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.91' }));
                } else if (res.data.message === 'Fob Not Found') {
                    return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.44' }));
                } else {
                    return FAILED(res, data, endpoint);
                }
            } else {
                let userData = JSON.parse(sessionStorage.getItem('user_data'))
                    ? JSON.parse(sessionStorage.getItem('user_data'))
                    : null;
                if (userData && userData.token && res.data.token && userData.token !== res.data.token) {
                    // updating token if backend sends us a new one
                    userData.token = res.data.token;
                    // also going to update the local storage object in case we need to relogin automatically for the user
                    if (localStorage.getItem('nse_login_data')) {
                        let tempLocalObject = JSON.parse(localStorage.getItem('nse_login_data'));
                        tempLocalObject.token = res.data.token;
                        localStorage.setItem('nse_login_data', JSON.stringify(tempLocalObject));
                    }
                }
                sessionStorage.setItem('user_data', JSON.stringify(userData));
                return res.data;
            }
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                console.warn(`Request to endpoint ${endpoint} canceled`, error.message);
            } else {
                if (error.message && error.message !== 'Request failed with status code 404') {
                    return FAILED_ERROR(error);
                }
            }
        });
};

//=====================================
// POST TEST -- used for testing in the Test Suite
//=====================================
export const POST_TEST = async (endpoint, data) => {
    (await endpoint) == '/site/switch/' && cancel('Operation canceled by the user');
    return await axios
        .post(URL_CHECKER() + endpoint, data, {
            headers: { Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user_data')).token },
            cancelToken: new CancelToken(function executor(c) {
                endpoint !== '/site/switch/' ? (cancel = c) : null;
            })
        })
        .then((res) => {
            if (!res.data){
                return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.10' }));
            } else if (res.data.outageOn && !currentOutage) {
                sessionStorage.setItem('current_outage', true);
            } else if (!res.data.outageOn && currentOutage) {
                sessionStorage.setItem('current_outage', false);
            }
            if (res.data.outagePending && !pendingOutage) {
                sessionStorage.setItem('pending_outage', true);
                sessionStorage.setItem('outage_time', res.data.outageAt);
            } else if (!res.data.outagePending && pendingOutage) {
                sessionStorage.setItem('pending_outage', false);
            }
            if (res.data.result === 'failure') {
                if (res.data.message === 'Work done, but could not send text and/or email to the user.') {
                    let userData = JSON.parse(sessionStorage.getItem('user_data'))
                        ? JSON.parse(sessionStorage.getItem('user_data'))
                        : null;
                    userData && userData.token && res.data.token && userData.token !== res.data.token
                        ? (userData.token = res.data.token)
                        : '';
                    sessionStorage.setItem('user_data', JSON.stringify(userData));
                } 
                return res.data;
            } else {
                let userData = JSON.parse(sessionStorage.getItem('user_data'))
                    ? JSON.parse(sessionStorage.getItem('user_data'))
                    : null;
                userData && userData.token && res.data.token && userData.token !== res.data.token
                    ? (userData.token = res.data.token)
                    : '';
                sessionStorage.setItem('user_data', JSON.stringify(userData));
                return res.data;
            }
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                console.warn(`Request to endpoint ${endpoint} canceled`, error.message);
            } else {
                return FAILED_ERROR(error);
            }
        });
};

//=====================================
// ACTIVITY POST - separate out identical to post request for activity, but don't update the token since it frequently takes 30+ seconds from the dashboard to fetch
//=====================================
export const POST_ACTIVITY = async (endpoint, data) => {
    (await endpoint) == '/site/switch/' && cancel('Operation canceled by the user');
    return await axios
        .post(URL_CHECKER() + endpoint, data, {
            headers: { Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user_data')).token },
            cancelToken: new CancelToken(function executor(c) {
                endpoint !== '/site/switch/' ? (cancel = c) : null;
            })
        })
        .then((res) => {
            if (!res.data) {
                return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.10' }));
            } else if (res.data.outageOn && !currentOutage) {
                sessionStorage.setItem('current_outage', true);
            } else if (!res.data.outageOn && currentOutage) {
                sessionStorage.setItem('current_outage', false);
            }
            if (res.data.outagePending && !pendingOutage) {
                sessionStorage.setItem('pending_outage', true);
                sessionStorage.setItem('outage_time', res.data.outageAt);
            } else if (!res.data.outagePending && pendingOutage) {
                sessionStorage.setItem('pending_outage', false);
            }
            if (res.data.result === 'failure') {
                if (res.data.message === 'Work done, but could not send text and/or email to the user.') {
                    let userData = JSON.parse(sessionStorage.getItem('user_data'))
                        ? JSON.parse(sessionStorage.getItem('user_data'))
                        : null;
                    userData && userData.token && res.data.token && userData.token !== res.data.token
                        ? (userData.token = res.data.token)
                        : '';
                    sessionStorage.setItem('user_data', JSON.stringify(userData));
                    return res.data;
                } else if (res.data.message === 'User already exists.') {
                    return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.14' }));
                } else if (res.data.message === 'User already has a role for the current site.') {
                    return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.91' }));
                } else if (res.data.message === 'Fob Not Found') {
                    return FAILED_ERROR(intl.formatMessage({ id: 'errorCodes.description.44' }));
                } else {
                    return FAILED(res, data, endpoint);
                }
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                console.warn(`Request to endpoint ${endpoint} canceled`, error.message);
            } else {
                return FAILED_ERROR(error);
            }
        });
};

//=====================================
// POST ERROR READ
//=====================================
export const POST_ERROR = async (endpoint, data) => {
    (await endpoint) == '/site/switch/' && cancel('Operation canceled by the user');
    return await axios
        .post(URL_CHECKER() + endpoint, data)
        .then((res) => {
            if (res.data.result === 'failure') {
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            if (axios.isCancel(error)) {
                console.warn(`Request to endpoint ${endpoint} canceled`, error.message);
            } else {
            }
        });
};

//=====================================
// POST TOKEN
//=====================================
export const POST_TOKEN = async (endpoint, data, token) =>
    await axios
        .post(URL_CHECKER() + endpoint, data, { headers: { Authorization: 'Bearer ' + token } })
        .then((res) => {
            if (res.data.result === 'failure') {
                return null;
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// POST SWITCH
//=====================================
export const POST_SWITCH = async (endpoint, data) =>
    await axios
        .post(URL_CHECKER() + endpoint, data, {
            headers: { Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user_data')).token }
        })
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED(res, data);
            } else {
                let userData = JSON.parse(sessionStorage.getItem('user_data'))
                    ? JSON.parse(sessionStorage.getItem('user_data'))
                    : null;
                userData && userData.token && res.data.token && userData.token !== res.data.token
                    ? (userData.token = res.data.token)
                    : '';
                sessionStorage.setItem('user_data', JSON.stringify(userData));
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// POST NO TOKEN
//=====================================
export const POST_OLD = async (endpoint, data) =>
    await axios
        .post(URL_CHECKER() + endpoint, data)
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED(res, data, endpoint);
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// GET
//=====================================
export const GET = async (url, data) =>
    await axios
        .get(URL_CHECKER() + url, data, {
            headers: { Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user_data')).token }
        })
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED(res, data, url);
            } else {
                return res;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// GATEWAY KEEPER POST
//=====================================
export const GATEWAY_KEEPER_POST = async (endpoint, data) =>
    await axios
        .post(URL_CHECKER() + endpoint, data, {
            headers: { Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user_data')).token }
        })
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED(res, data, endpoint);
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

export const GATEWAY_KEEPER_POST_OLD = async (endpoint, data) =>
    await axios
        .post(URL_CHECKER_GATEWAY_KEEPER() + endpoint, data, {
            headers: { Authorization: `APIKey ${API_CHECKER_GATEWAY_KEEPER()}` }
        })
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED(res, data, endpoint);
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// GATEWAY KEEPER GET
//=====================================
export const GATEWAY_KEEPER_GET = async (endpoint, data) =>
    await axios
        .get(URL_CHECKER_GATEWAY_KEEPER() + endpoint, data, {
            headers: { Authorization: `APIKey ${API_CHECKER_GATEWAY_KEEPER()}` }
        })
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED(res, data, endpoint);
            } else {
                return res;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// REPORTS POST
//=====================================
export const POST_REPORTS = async (url, data) =>
    await axios
        .post(ENDPOINTS.URL_REPORTS + url, data)
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED_REPORTS(res);
            } else {
                return res;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// REPORTS GET
//=====================================
export const GET_REPORTS = async (url, data) =>
    await axios
        .get(ENDPOINTS.URL_REPORTS + url, data)
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED_REPORTS(res);
            } else {
                return res;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// ADAM
//=====================================
export const ADAM = async (endpoint, data) =>
    await axios
        .post(ENDPOINTS.URL_ADAM + endpoint, data, {
            headers: { Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem('user_data')).token }
        })
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED(res, data, endpoint);
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// MAIL
//=====================================
export const MAIL = async (endpoint, data) =>
    await axios
        .post(endpoint, data)
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED(res, data, endpoint);
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_ERROR(error);
        });

//=====================================
// WEATHER
//=====================================
export const WEATHER = async (lat, lon) =>
    await axios
        .post(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${CONSTANTS.WEATHER}`)
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED_WARN(res.data.result);
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_WARN(error);
        });

export const WEATHER_WEEK = async (lat, lon) =>
    await axios
        .post(`https://api.openweathermap.org/data/2.5/forecast?lat=${lat}&lon=${lon}&appid=${CONSTANTS.WEATHER}`)
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED_WARN(res.data.result);
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_WARN(error);
        });

export const AIR_QUALITY = async (lat, lon) =>
    await axios
        .post(`https://api.weatherapi.com/v1/forecast.json?key=${CONSTANTS.WEATHER_API}&q=${lat},${lon}&aqi=yes&days=5`)
        .then((res) => {
            if (res.data.result === 'failure') {
                return FAILED_WARN(res.data.result);
            } else {
                return res.data;
            }
        })
        .catch((error) => {
            return FAILED_WARN(error);
        });
