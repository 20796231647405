import * as ENDPOINTS from 'constants/endpoints';
import * as CONSTANTS from 'constants';
import * as DATABASE from 'utilities/database';

export const fetchActivityList = ({
    page = 1,
    filter = null,
    limit = 200,
    map = false,
    dash = false,
    day = false,
    type = 'all'
}) => {
    return {
        type: CONSTANTS.FETCH_ACTIVITY_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_ACTIVITY_LIST_SEARCH,
            filter,
            page: JSON.parse(page),
            map,
            dash,
            type,
            day,
            data: {
                details: {
                    page: {
                        limit,
                        offset: page === 0 ? 0 : page === 1 ? 0 : (page - 1) * limit
                    },
                    range: {
                        begin: filter && filter.date && `${filter.date.start}T00:00:00Z`,
                        end: filter && filter.date && `${filter.date.end}T23:59:59Z`
                    }
                },
                subtype: filter && filter.subtypes ? filter.subtypes : DATABASE.ACTIVITY_SUBTYPES,
                performerUUIDs: filter && filter.users,
                unitUUIDs: filter && filter.units,
                lockUUIDs: filter && filter.locks,
                eventUUID: filter && filter.uuid,
                roleUUIDs: filter && filter.roles
            }
        }
    };
};

export const fetchActivityStats = ({
    page = 1,
    filter = null,
    limit = 200,
    map = false,
    dash = false,
    day = false,
    type = 'all'
}) => {
    return {
        type: CONSTANTS.FETCH_ACTIVITY_STATS,
        payload: {
            url: ENDPOINTS.ENDPOINT_ACTIVITY_STATS,
            filter,
            page: JSON.parse(page),
            map,
            dash,
            type,
            day,
            data: {
                details: {
                    page: {
                        limit,
                        offset: page === 0 ? 0 : page === 1 ? 0 : (page - 1) * limit
                    },
                    range: {
                        begin: filter && filter.date && `${filter.date.start}T00:00:00Z`,
                        end: filter && filter.date && `${filter.date.end}T23:59:59Z`
                    }
                },
                subtype: filter && filter.subtypes ? filter.subtypes : DATABASE.ACTIVITY_SUBTYPES,
                performerUUIDs: filter && filter.users,
                unitUUIDs: filter && filter.units,
                lockUUIDs: filter && filter.locks,
                eventUUID: filter && filter.uuid,
                roleUUIDs: filter && filter.roles
            }
        }
    };
};

export const fetchActivityCSVList = (page = 1, filter = {}, siteUUIDs = []) => {
    return {
        type: CONSTANTS.FETCH_ACTIVITY_CSV_LIST,
        payload: {
            url: ENDPOINTS.ENDPOINT_ACTIVITY_CSV_LIST,
            filter,
            page,
            data: {
                body: {
                    details: {
                        range: {
                            begin: filter && `${filter.date.start}T00:00:00Z`,
                            end: filter && `${filter.date.end}T23:59:59Z`
                        }
                    },
                    subtype: filter && filter.subtypes ? filter.subtypes : DATABASE.ACTIVITY_SUBTYPES,
                    performerUUIDs: filter && filter.users,
                    unitUUIDs: filter && filter.units,
                    lockUUIDs: filter && filter.locks ? filter.locks : null,
                    roleUUIDs: filter && filter.roles,
                    siteUUIDs
                },
                callbackUrl: `${sessionStorage.getItem('nse_url')}/activity/search/export/email/`,
                companyUUID: localStorage.getItem('nse_login_data').company,
                toEmail: JSON.parse(sessionStorage.getItem('user_data')).email
            }
        }
    };
};

export const setActivityList = (data = []) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_LIST,
        payload: data
    };
};

export const setUserActivityList = (data = []) => {
    return {
        type: CONSTANTS.SET_USER_ACTIVITY_LIST,
        payload: data
    };
};

export const setUnitActivityList = (data = []) => {
    return {
        type: CONSTANTS.SET_UNIT_ACTIVITY_LIST,
        payload: data
    };
};

export const setEntryActivityList = (data = []) => {
    return {
        type: CONSTANTS.SET_ENTRY_ACTIVITY_LIST,
        payload: data
    };
};

export const setActivityCSVList = (data = []) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_CSV_LIST,
        payload: data
    };
};

export const setActivityHeaders = (data) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_HEADERS,
        payload: data
    };
};

export const setActivityFilters = (data) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_FILTERS,
        payload: data
    };
};

export const setSupportActivityFilters = (data) => {
    return {
        type: CONSTANTS.SET_SUPPORT_ACTIVITY_FILTERS,
        payload: data
    };
};

export const setMapActivityFilters = (data) => {
    return {
        type: CONSTANTS.SET_MAP_ACTIVITY_FILTERS,
        payload: data
    };
};

export const setMotionActivityFilters = (data) => {
    return {
        type: CONSTANTS.SET_MOTION_ACTIVITY_FILTERS,
        payload: data
    };
};

export const setActivityDashboardFilters = (data) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_DASHBOARD_FILTERS,
        payload: data
    };
};

export const setActivityPageCount = (data) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_PAGE_COUNT,
        payload: data
    };
};

export const setActivityCurrentIndex = (data) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_CURRENT_INDEX,
        payload: data
    };
};

export const setRecentUsers = (data) => {
    return {
        type: CONSTANTS.SET_RECENT_USERS,
        payload: data
    };
};

export const setRecentUnits = (data) => {
    return {
        type: CONSTANTS.SET_RECENT_UNITS,
        payload: data
    };
};

export const setActivityDates = (data) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_DATES,
        payload: data
    };
};

export const fetchTenantActivity = ({ interval = '', period = '', timeZone }) => {
    return {
        type: CONSTANTS.FETCH_TENANT_ACTIVITY,
        payload: {
            url: '/activity/breakdown/user/',
            data: {
                interval,
                period,
                timeZone
            }
        }
    };
};

export const setTenantActivity = (data) => {
    return {
        type: CONSTANTS.SET_TENANT_ACTIVITY,
        payload: data
    };
};

export const fetchUserActivityFilters = ({ uuid = '', fetch = false }) => {
    return {
        type: CONSTANTS.FETCH_USER_ACTIVITY_FILTERS,
        payload: {
            url: '/activity/filter/read/',
            data: {},
            uuid,
            fetch
        }
    };
};

export const setUserActivityFilters = (data) => {
    return {
        type: CONSTANTS.SET_USER_ACTIVITY_FILTERS,
        payload: data
    };
};

export const setUserActivityFilter = (data) => {
    return {
        type: CONSTANTS.SET_USER_ACTIVITY_FILTER,
        payload: data
    };
};

export const createUserActivityFilter = ({ filterName = '', json = '' }) => {
    return {
        type: CONSTANTS.CREATE_USER_ACTIVITY_FILTER,
        payload: {
            url: '/activity/filter/create/',
            data: {
                filterName,
                json: JSON.stringify(json)
            }
        }
    };
};

export const updateUserActivityFilter = ({ filterUUID = '', filterName = '', json = '' }) => {
    return {
        type: CONSTANTS.UPDATE_USER_ACTIVITY_FILTER,
        payload: {
            url: '/activity/filter/edit/',
            data: {
                filterUUID,
                filterName,
                json: JSON.stringify(json)
            }
        }
    };
};

export const deleteUserActivityFilter = ({ filterUUID = '' }) => {
    return {
        type: CONSTANTS.DELETE_USER_ACTIVITY_FILTER,
        payload: {
            url: '/activity/filter/delete/',
            data: {
                filterUUID
            }
        }
    };
};

export const setActivityView = (data) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_VIEW,
        payload: data
    };
};

export const setActivityDefaultView = (filterUUID) => {
    return {
        type: CONSTANTS.SET_ACTIVITY_DEFAULT_VIEW,
        payload: {
            url: '/activity/filter/default/update/',
            data: {
                filterUUID
            }
        }
    };
};
