//||||||||||||||||||||||||||||||||||||||||
//|| ACTIVITY LIST                      ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ACTIVITY_LIST = '/activity/';
export const PURPOSE_ACTIVITY_LIST = 'Fetch a list of the site activity.';
export const PERMISSIONS_ACTIVITY_LIST = [];
export const REQUIREMENTS_ACTIVITY_LIST = ['Token Required'];
export const PARAMETER_ACTIVITY_LIST = {
    companyId: 0,
    siteId: 0,
    page: 0
};
export const RETURN_ACTIVITY_LIST = {
    activity: [
        {
            action: '',
            actionDetails: '',
            actionType: '',
            alarmCount: 0,
            assignedUserEmail: '',
            assignedUserId: 0,
            assignedUserName: '',
            assignedUserPhone: '',
            companyId: 0,
            employeeEmail: '',
            employeeId: 0,
            employeeName: '',
            employeePhone: '',
            employeeType: '',
            id: 0,
            lockId: 0,
            lockName: '',
            lockType: '',
            rangeDateEnd: '',
            rangeDateStart: '',
            recordedDate: '',
            shareUserEmail: '',
            shareUserId: 0,
            shareUserName: '',
            shareUserPhone: '',
            siteId: 0,
            unitId: 0,
            unitName: ''
        }
    ],
    page: 0,
    pageCount: 0
};

//||||||||||||||||||||||||||||||||||||||||
//|| ACTIVITY LIST SEARCH               ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ACTIVITY_LIST_SEARCH = '/activity/search/';
export const PURPOSE_ACTIVITY_LIST_SEARCH = 'Filter a list of the site activity.';
export const PERMISSIONS_ACTIVITY_LIST_SEARCH = [];
export const REQUIREMENTS_ACTIVITY_LIST_SEARCH = ['Token Required'];
export const PARAMETER_ACTIVITY_LIST_SEARCH = {
    details: {
        page: {
            limit: 0,
            offset: 0
        },
        range: {
            begin: 'time',
            end: 'time'
        }
    },
    companyId: 0,
    siteId: 0,
    level: '',
    type: '',
    subtype: '',
    byId: 0,
    forId: 0,
    unitId: 0,
    lockId: 0
};
//||||||||||||||||||||||||||||||||||||||||
//|| ACTIVITY LIST STATS              ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ACTIVITY_STATS = '/activity/stats/';
export const PURPOSE_ACTIVITY_STATS = 'Daily stats for activity';
export const PERMISSIONS_ACTIVITY_STATS = [];
export const REQUIREMENTS_ACTIVITY_STATS = ['Token Required'];
export const PARAMETER_ACTIVITY_STATS = {
    details: {
        page: {
            limit: 0,
            offset: 0
        },
        range: {
            begin: 'time',
            end: 'time'
        }
    },
    companyId: 0,
    siteId: 0,
    level: '',
    type: '',
    subtype: '',
    byId: 0,
    forId: 0,
    unitId: 0,
    lockId: 0
};
export const RETURN_ACTIVITY_LIST_SEARCH = {
    activity: [
        {
            ID: '',
            CreatedAt: 'time',
            UpdatedAt: 'time',
            DeletedAt: 'time',
            Type: '',
            Data: {
                Version: '',
                Type: '',
                SiteID: '',
                Level: '',
                Subtype: '',
                By: {
                    UserID: '',
                    CompanyID: '',
                    FirstName: '',
                    LastName: '',
                    Email: '',
                    Phone: '',
                    Type: ''
                },
                For: {
                    UserID: '',
                    CompanyID: '',
                    FirstName: '',
                    LastName: '',
                    Email: '',
                    Phone: '',
                    Type: ''
                },
                Unit: {
                    ID: '',
                    Name: '',
                    RentalState: '',
                    AccessType: '',
                    User: {
                        UserID: '',
                        CompanyID: '',
                        FirstName: '',
                        LastName: '',
                        Email: '',
                        Phone: '',
                        Type: ''
                    },
                    ExternalId: '',
                    Width: '',
                    Depth: '',
                    Price: '',
                    DetailsComments: '',
                    Locks: [
                        {
                            ID: '',
                            Name: '',
                            Mac: '',
                            HwType: '',
                            SyncFlag: '',
                            SetupCount: 0
                        }
                    ]
                }
            },
            ByUser: '',
            ForUser: '',
            LockID: '',
            UnitID: '',
            Subtype: '',
            Level: ''
        }
    ],
    page: 0,
    pageCount: 0
};

//||||||||||||||||||||||||||||||||||||||||
//|| ACTIVITY CSV LIST                  ||
//||||||||||||||||||||||||||||||||||||||||
export const ENDPOINT_ACTIVITY_CSV_LIST = '/activity/search/export/';
export const PURPOSE_ACTIVITY_CSV_LIST = 'Fetch a list of the site activity to be downloaded to csv.';
export const PERMISSIONS_ACTIVITY_CSV_LIST = [];
export const REQUIREMENTS_ACTIVITY_CSV_LIST = ['Token Required'];
export const PARAMETER_ACTIVITY_CSV_LIST = {
    companyId: 0,
    siteId: 0,
    page: 0
};
export const RETURN_ACTIVITY_CSV_LIST = {
    activity: [
        {
            action: '',
            actionDetails: '',
            actionType: '',
            alarmCount: 0,
            assignedUserEmail: '',
            assignedUserId: 0,
            assignedUserName: '',
            assignedUserPhone: '',
            companyId: 0,
            employeeEmail: '',
            employeeId: 0,
            employeeName: '',
            employeePhone: '',
            employeeType: '',
            id: 0,
            lockId: 0,
            lockName: '',
            lockType: '',
            rangeDateEnd: '',
            rangeDateStart: '',
            recordedDate: '',
            shareUserEmail: '',
            shareUserId: 0,
            shareUserName: '',
            shareUserPhone: '',
            siteId: 0,
            unitId: 0,
            unitName: ''
        }
    ],
    page: 0,
    pageCount: 0
};
